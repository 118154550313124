import React, { useState, useEffect } from "react";
import "fontsource-roboto";
import Message from "@material-ui/icons/Message";
import IconButton from "@material-ui/core/IconButton";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Delete from "@material-ui/icons/Delete";
import { SketchPicker } from "react-color";
import Popover from "@material-ui/core/Popover";

function CommentCard(props) {
  const [fillColor, setFillColor] = useState();
  const [borderColor, setBorderColor] = useState("rgba(255,0,0,1)");
  const [editingColor, setEditingColor] = useState();
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setFillColor(props.backgroundColor);
    setBorderColor(props.borderColor);
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const setColor = (color) => {
    var newColor =
      "rgba(" +
      color.rgb.r +
      "," +
      color.rgb.g +
      "," +
      color.rgb.b +
      "," +
      color.rgb.a +
      ")";
    var newBorderColor = borderColor;
    var newBackgroundColor = fillColor;
    if (editingColor === "border") {
      setBorderColor(newColor);
      newBorderColor = newColor;
    } else {
      setFillColor(newColor);
      newBackgroundColor = newColor;
    }
    props.onChangeColor(
      { backgroundColor: newBackgroundColor, borderColor: newBorderColor },
      props.id
    );
  };

  var colorPicker = (
    <Popover
      id={"colorPicker"}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <SketchPicker
        color={editingColor === "border" ? borderColor : fillColor}
        onChangeComplete={(color) => setColor(color)}
      />
    </Popover>
  );

  return (
    <div
      style={{
        ...styles.outterCard,
        marginLeft: props.showingCards ? "0px" : "40px",
      }}
    >
      {props.isSelected ? (
        <div style={{ ...styles.leftBar, backgroundColor: fillColor }}></div>
      ) : (
        <div />
      )}
      <Paper style={styles.card} id={props.if} onClick={props.onClick}>
        <div style={{ margin: "10px" }}>
          <span>
            <b>Comentado por:</b> {props.data.teacher}
          </span>
          <TextField
            id="standard-multiline-flexible"
            label="Comentário"
            multiline
            rows={4}
            variant="outlined"
            fullWidth={true}
            value={props.text}
            onChange={props.readOnly ? null : props.onChangeText}
            style={{ marginTop: "10px" }}
          />

          <div style={styles.colorDisplay}>
            {props.type !== "sticker" ? (
              <div style={{ maxWidth: "250px" }}>
                <div style={{ display: "flex" }}>
                  <span style={styles.label}>Fundo: </span>
                  <div
                    style={{ ...styles.colorDot, backgroundColor: fillColor }}
                    onClick={(event) => {
                      if (!props.readOnly) {
                        setAnchorEl(event.currentTarget);
                        setEditingColor("fill");
                      }
                    }}
                  />
                  <span style={styles.label}>Borda: </span>
                  <div
                    style={{ ...styles.colorDot, backgroundColor: borderColor }}
                    onClick={(event) => {
                      if (!props.readOnly) {
                        setAnchorEl(event.currentTarget);
                        setEditingColor("border");
                      }
                    }}
                  />
                </div>
                <div style={{ display: "flex" }}></div>
              </div>
            ) : (
              <div />
            )}
            {props.readOnly ? (
              <div />
            ) : (
              <div onClick={props.onDelete} style={{ cursor: "pointer" }}>
                <Delete
                  style={{
                    ...styles.delete,
                    marginTop: props.type !== "sticker" ? "-25px" : "0px",
                  }}
                />
              </div>
            )}
          </div>
          {colorPicker}
        </div>
      </Paper>
    </div>
  );
}

const styles = {
  card: {
    height: "230px",
    width: "90%",
    marginTop: "20px",
    marginLeft: "20px",
  },
  outterCard: {
    width: "100%",
    display: "flex",
    transition: "margin 1s",
  },
  colorDot: {
    height: "20px",
    width: "20px",
    borderRadius: "50%",
    borderStyle: "solid",
    marginRight: "10px",
    marginLeft: "10px",
    borderWidth: "2px",
  },
  colorDisplay: {
    marginTop: "10px",
    marginLeft: "5px",
  },
  delete: {
    float: "right",
    color: "rgb(67,67,67)",
  },
  leftBar: {
    height: "170px",
    width: "10px",
    marginTop: "20px",
    borderTopRightRadius: "5px",
    borderBottomRightRadius: "5px",
  },
  label: {
    fontWeight: 500,
  },
};

export default CommentCard;
