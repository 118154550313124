import redação from "./redação.png";
import "./App.css";
import React, { useState, useEffect } from "react";
import ToolBar from "./ToolBar";
import Settings from "./Settings";
import Card from "./CommentCard";
import ResizableRect from "./react-resizable-rotatable-draggable/src/";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useSessionStorage } from "react-use-storage";
import { VerifyAuth, goTo, getDate, baseURL } from "./webHandler";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Slider from "@material-ui/core/Slider";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { checkboxSelectionColDef } from "@material-ui/data-grid";

function EssayCorrector() {
  const [mouseDownPoint, setMouseDownPoint] = useState([]);
  const [transcription, setTranscription] = useState("");
  const [transcriptionLenght, setTranscriptionLenght] = useState(0);
  const [hasMoved, setMoved] = useState(false);
  const [essayZoom, setEssayZoom] = useState(100);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    essayWidth: window.innerWidth * 0.7,
  });
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [showingLoad, setShowingLoad] = useState(true);
  const [showElements, setShowElements] = useState(true);
  const [ratio, setRatio] = useState(0);
  const [selectedTool, setSelectedTool] = useState("commentArea");
  const [mouseDown, setMouseDown] = useState(false);

  const [bearer, setBearer] = useSessionStorage("bearer");

  const [elements, setElements] = useState([]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const [selectionArea, setSelectionArea] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });

  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedSticker, setSelectedSticker] = useState(null);
  const [hoveredElement, setHoveredElement] = useState(null);
  const [quickComment, setQuickComment] = useState();

  const [defaultBackgroundColor, setDefaultBackgroundColor] =
    useState("rgba(255,0,0,0.5)");
  const [defaultBorderColor, setDefaultBorderColor] =
    useState("rgba(255,0,0,1)");

  const [rightBarExpanded, setRightBarExpanded] = useState(true);
  const [showingCards, setShowingCards] = useState(true);
  const [cardsVisible, setCardsVisible] = useState(true);

  const [activeStep, setActiveStep] = useState(0);
  const [comment1, setComment1] = useState("");
  const [comment2, setComment2] = useState("");
  const [comment3, setComment3] = useState("");
  const [comment4, setComment4] = useState("");
  const [comment5, setComment5] = useState("");
  const [finalComment, setFinalComment] = useState("");
  const [isFinal, setFinal] = useState(false);
  const [gradeConfig, setGradeConfig] = useState();

  const [autoSaving, setAutoSaving] = useState();

  const [slider1, setSlider1] = useState(0);
  const [slider2, setSlider2] = useState(0);
  const [slider3, setSlider3] = useState(0);
  const [slider4, setSlider4] = useState(0);
  const [slider5, setSlider5] = useState(0);

  const [assignments, setAssignments] = useState([]);
  const [assignmentIndex, setAssignmentIndex] = useState(0);

  const [textURL, setTextURL] = useState("");

  const [cachedElements, setCachedElements] = useState([]);

  const [user, setUser] = useState({});

  useEffect(() => {
    VerifyAuth(query.get("tka"), setBearer, function (tokenA, currentUser) {
      var xhrUser = new XMLHttpRequest();
      xhrUser.open("GET", baseURL + "u/", true);
      xhrUser.setRequestHeader("authorization", "Bearer " + tokenA);
      xhrUser.addEventListener("load", () => {
        setUser(JSON.parse(xhrUser.responseText));
      });
      xhrUser.send();
    });
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log("Interval");
  //   }, 10000);

  //   return () => clearInterval(interval);
  // }, []);

  let year = query.get("year");

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    checkCache();
  }, [cachedElements]);

  var saveAllComments = function (array) {
    if (array && array.length > 0) {
      var body = array.map(function (e) {
        var x = {
          id: e.id,
          positions: {
            top: e.originalTop,
            height: e.originalHeight,
            width: e.originalWidth,
            left: e.originalLeft,
          },
          type: e.type,
          teacher: e.teacher ? e.teacher : user.name,
          comment: e.text ? e.text : " ",
        };
        if (e.type != "sticker") {
          x.style = e.colors;
        } else {
          x.sticker = e.sticker;
        }
        return x;
      });

      if (body.length > 0) {
        if (query.get("tka") != null) {
          var xhr4 = new XMLHttpRequest();
          xhr4.addEventListener("load", () => {});

          xhr4.open(
            "POST",
            baseURL +
              "o/" +
              query.get("oid") +
              "/groups/" +
              query.get("groupId") +
              "/texts/" +
              query.get("textId") +
              "/users/" +
              query.get("correctionId") +
              "/corrections/" +
              query.get("correctorId") +
              "/comments",
            true
          );
          xhr4.setRequestHeader("Content-Type", "application/json");
          xhr4.setRequestHeader("authorization", "Bearer " + query.get("tka"));
          xhr4.send(JSON.stringify(body));
        } else {
          VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
            var xhr4 = new XMLHttpRequest();
            xhr4.addEventListener("load", () => {});

            xhr4.open(
              "POST",
              baseURL +
                "o/" +
                query.get("oid") +
                "/groups/" +
                query.get("groupId") +
                "/texts/" +
                query.get("textId") +
                "/users/" +
                query.get("correctionId") +
                "/corrections/" +
                query.get("correctorId") +
                "/comments",
              true
            );
            xhr4.setRequestHeader("Content-Type", "application/json");
            xhr4.setRequestHeader("authorization", "Bearer " + tokenA);
            xhr4.send(JSON.stringify(body));
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!autoSaving) {
      setAutoSaving(
        setTimeout(function () {
          saveAllComments(elements);
          setAutoSaving(null);
        }, 4000)
      );
    } else {
      clearTimeout(autoSaving);
      setAutoSaving(
        setTimeout(function () {
          saveAllComments(elements);
          setAutoSaving(null);
        }, 4000)
      );
    }
  }, [elements]);

  useEffect(() => {
    // Update screen dimensions on resize
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
        essayWidth: window.innerWidth * 0.7,
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    // update elements on ratio change
    var auxRatio = imageDimensions.width / dimensions.essayWidth;
    var zoom = essayZoom / 100;
    setRatio(auxRatio);
    setElements(
      elements.map((element) => {
        return {
          ...element,
          teacher: element.teacher ? element.teacher : user.name,
          left: Math.round((element.originalLeft * zoom) / auxRatio),
          top: Math.round((element.originalTop * zoom) / auxRatio),
          height: Math.round((element.originalHeight * zoom) / auxRatio),
          width: Math.round((element.originalWidth * zoom) / auxRatio),
        };
      })
    );
  }, [dimensions, essayZoom, imageDimensions]);

  useEffect(() => {
    var xhrUser = new XMLHttpRequest();
    xhrUser.open("GET", baseURL + "u/", true);
    if (query.get("tka") != null) {
      var xhr = new XMLHttpRequest();
      xhr.addEventListener("load", () => {
        setTranscriptionLenght(
          JSON.parse(xhr.responseText).transcription.length
        );
        setTranscription(
          JSON.parse(xhr.responseText).transcription
            ? JSON.parse(xhr.responseText).transcription
            : ""
        );
        setTextURL(JSON.parse(xhr.responseText).photoURL);
        setTimeout(function () {
          setImageDimensions({
            width: document.getElementById("essay").naturalWidth,
            height: document.getElementById("essay").naturalHeight,
          });
          setShowingLoad(false);
        }, 2000);
      });
      if (query.get("year") != null) {
        xhr.open(
          "GET",
          baseURL +
            "o/" +
            query.get("oid") +
            "/groups/" +
            query.get("groupId") +
            "/texts/" +
            query.get("textId") +
            "/users/" +
            query.get("correctionId") +
            "/corrections/photourl/" +
            query.get("year"),
          true
        );
      } else {
        xhr.open(
          "GET",
          baseURL +
            "o/" +
            query.get("oid") +
            "/groups/" +
            query.get("groupId") +
            "/texts/" +
            query.get("textId") +
            "/users/" +
            query.get("correctionId") +
            "/corrections/photourl",
          true
        );
      }
      // xhr.open("GET", baseURL+'o/'+query.get("oid")+'/groups/'+query.get("groupId")+'/texts/'+query.get("textId")+'/users/'+query.get("correctionId")+'/corrections/text', true);
      xhr.setRequestHeader("authorization", "Bearer " + query.get("tka"));
      xhr.send();

      //

      var xhr2 = new XMLHttpRequest();
      xhr2.onreadystatechange = function () {
        if (xhr2.readyState === 4) {
          var x = JSON.parse(xhr2.responseText).criteria;
          var final = JSON.parse(xhr2.responseText).config.final;
          var config = JSON.parse(xhr2.responseText).config;
          if (config) {
            setGradeConfig(config.grade);
          }
          if (query.get("oid") == "0TsXrUs") {
            if (final) {
              setFinal(true);
            }
          } else {
            if (final) {
              setFinalComment(
                "Olá, tudo bem?Obrigado pelo envio. No relatório final, colocamos uma grade de notas para que você entenda como é avaliado cada critério e isso justifica sua nota final. Assim, finalizamos o segundo trimestre. Estamos sempre à disposição. Equipe Grafiame."
              );
              setFinal(true);
            }
          }

          var array = [{}, {}, {}, {}, {}];
          array = array.map(function (a, index) {
            if (x[index]) {
              return x[index];
            } else {
              return null;
            }
          });

          array = array.filter(function (a) {
            return a;
          });

          array.forEach(function (a, index) {
            var i = index + 1;
            switch (i) {
              case 1:
                // setSlider1(a.grade ? a.grade.grade : 0);
                // if(a.comment != ""){
                //   setComment1(a.comment);
                // }
                // else{
                //   setComment1(a.comment + "- ADEQUAÇÃO AO NÍVEL DE LINGUAGEM\nZero – Insuficiência vocabular e erros gramaticais sérios que comprometem o entendimento o entendimento do texto. Para a refeitura, é necessário reestruturar toda a linguagem.\n0.5 – Variedade vocabular insuficiente, interferências da oralidade, algumas incorreções gramaticais. Para a refeitura, corrija sua redação para que esses erros sejam sanados\n1.0 – Apresenta variedade vocabular, mas alguns desvios recorrentes no uso das regras gramaticais (acentuação, pontuação, ortografia, plural, etc).\n1.5 – Adequação vocabular e gramatical, bom domínio das regras normativas da língua escrita. Para a refeitura, reveja a possibilidade de construções criativas das frases.\n2.0 – Aproveitamento ótimo de todos os recursos da Língua Portuguesa, criatividade na construção das frases que são originais e bem elaboradas.");
                // }
                // break;
                setSlider1(a.grade ? a.grade.grade : 0);
                if (query.get("oid") == "0TsXrUs") {
                  if (a.comment != "") {
                    setComment1(a.comment);
                  } else {
                    setComment1(
                      a.comment +
                        "- ADEQUAÇÃO AO NÍVEL DE LINGUAGEM\nZero – Insuficiência vocabular e erros gramaticais sérios que comprometem o entendimento o entendimento do texto. Para a refeitura, é necessário reestruturar toda a linguagem.\n0.5 – Variedade vocabular insuficiente, interferências da oralidade, algumas incorreções gramaticais. Para a refeitura, corrija sua redação para que esses erros sejam sanados\n1.0 – Apresenta variedade vocabular, mas alguns desvios recorrentes no uso das regras gramaticais (acentuação, pontuação, ortografia, plural, etc).\n1.5 – Adequação vocabular e gramatical, bom domínio das regras normativas da língua escrita. Para a refeitura, reveja a possibilidade de construções criativas das frases.\n2.0 – Aproveitamento ótimo de todos os recursos da Língua Portuguesa, criatividade na construção das frases que são originais e bem elaboradas."
                    );
                  }
                } else {
                  if (a.comment != "") {
                    setComment1(a.comment);
                  } else {
                    setComment1(
                      a.comment +
                        "- ADEQUAÇÃO AO NÍVEL DE LINGUAGEM\nZero – Insuficiência vocabular e erros gramaticais sérios que comprometem o entendimento o entendimento do texto. Para a refeitura, é necessário reestruturar toda a linguagem.\n0.5 – Variedade vocabular insuficiente, interferências da oralidade, algumas incorreções gramaticais. Para a refeitura, corrija sua redação para que esses erros sejam sanados\n1.0 – Apresenta variedade vocabular, mas alguns desvios recorrentes no uso das regras gramaticais (acentuação, pontuação, ortografia, plural, etc).\n1.5 – Adequação vocabular e gramatical, bom domínio das regras normativas da língua escrita. Para a refeitura, reveja a possibilidade de construções criativas das frases.\n2.0 – Aproveitamento ótimo de todos os recursos da Língua Portuguesa, criatividade na construção das frases que são originais e bem elaboradas."
                    );
                  }
                }
                break;
              case 2:
                // setSlider2(a.grade ? a.grade.grade : 0);
                // if(a.comment != ""){
                //   setComment2(a.comment);
                // } else if(query.get("groupId") == "FfwUAMAF6T" || query.get("groupId") == "jRub8YU8yE" || query.get("groupId")== "nmgdhzmq4z" || query.get("groupId") == "e03Q9Utgg5" || query.get("groupId")== "HsKXbsqYP6" || query.get("groupId")=="jw1Mtnpf4V"){
                //   setComment2(a.comment + "– ADEQUAÇÃO AO TEMA\nZero – Foge completamente ao tema.   \n0.5 – Explora o assunto e aborda superficialmente o tema; parafraseando os textos da proposta.\n1.0 – Aborda parcialmente o tema, em que o tema não é retomado em todos os parágrafos.\n1.5 – Aborda adequadamente; fugiu da paráfrase e trouxe ideias da coletânea sem cópia.\n2.0 – Contribuição pessoal, informatividade: algo inusitado (ideia inovadora).\n\n- ADEQUAÇÃO AO TIPO DE COMPOSIÇÃO (GÊNERO)\nZero – Foge completamente ao tipo de composição.	\n0.5 – Foge parcialmente (mistura de modalidades textuais como ora opinião e ora desabafo)\n1.0 – Atende ao tipo, mas apresenta falha na estrutura (argumentação sem a separação de causa e consequência ou conclusão que não atende a proposta de intervenção ou uma reflexão).\n1.5 – Atende ao tipo, mas não explora e/ou utiliza todos os recursos da modalidade; apresentando um projeto pessoal temático bem construído, porém de forma esperada.\n2.0 – Aproveitamento ótimo de todos os recursos estruturais de texto e desenvolvimento dos elementos constituintes do gênero como boa paragrafação, simetria entre os parágrafos, número de linhas suficientes para explicar as ideias, o tema sempre presenta nos parágrafos.")
                // }else{
                // setComment2(a.comment + "– ADEQUAÇÃO AO TEMA\nZero – Foge completamente ao tema.   \n0.5 – Explora o assunto e aborda superficialmente o tema; parafraseando os textos da proposta.\n1.0 – Aborda parcialmente o tema, em que o tema não é retomado em todos os parágrafos.\n1.5 – Aborda adequadamente; fugiu da paráfrase e trouxe ideias da coletânea sem cópia.\n2.0 – Contribuição pessoal, informatividade: algo inusitado (ideia inovadora).\n\n- ADEQUAÇÃO AO TIPO DE COMPOSIÇÃO (GÊNERO)\nZero – Foge completamente ao tipo de composição.	\n0.5 – Foge parcialmente (mistura de modalidades textuais como ora opinião e ora desabafo)\n1.0 – Atende ao tipo, mas apresenta falha na estrutura (argumentação sem a separação de causa e consequência ou conclusão que não atende a proposta de intervenção ou uma reflexão).\n1.5 – Atende ao tipo, mas não explora e/ou utiliza todos os recursos da modalidade; apresentando um projeto pessoal temático bem construído, porém de forma esperada.\n2.0 – Aproveitamento ótimo de todos os recursos estruturais de texto e desenvolvimento dos elementos constituintes do gênero como boa paragrafação, simetria entre os parágrafos, número de linhas suficientes para explicar as ideias, o tema sempre presenta nos parágrafos.");
                // }
                // break;
                setSlider2(a.grade ? a.grade.grade : 0);
                if (query.get("oid") == "0TsXrUs") {
                  if (a.comment != "") {
                    setComment2(a.comment);
                  } else if (
                    query.get("groupId") == "FfwUAMAF6T" ||
                    query.get("groupId") == "jRub8YU8yE" ||
                    query.get("groupId") == "nmgdhzmq4z" ||
                    query.get("groupId") == "e03Q9Utgg5" ||
                    query.get("groupId") == "HsKXbsqYP6" ||
                    query.get("groupId") == "jw1Mtnpf4V"
                  ) {
                    setComment2(
                      a.comment +
                        "– ADEQUAÇÃO AO TEMA\nZero – Foge completamente ao tema.   \n0.5 – Explora o assunto e aborda superficialmente o tema; parafraseando os textos da proposta.\n1.0 – Aborda parcialmente o tema, em que o tema não é retomado em todos os parágrafos.\n1.5 – Aborda adequadamente; fugiu da paráfrase e trouxe ideias da coletânea sem cópia.\n2.0 – Contribuição pessoal, informatividade: algo inusitado (ideia inovadora).\n\n- ADEQUAÇÃO AO TIPO DE COMPOSIÇÃO (GÊNERO)\nZero – Foge completamente ao tipo de composição.	\n0.5 – Foge parcialmente (mistura de modalidades textuais como ora opinião e ora desabafo)\n1.0 – Atende ao tipo, mas apresenta falha na estrutura (argumentação sem a separação de causa e consequência ou conclusão que não atende a proposta de intervenção ou uma reflexão).\n1.5 – Atende ao tipo, mas não explora e/ou utiliza todos os recursos da modalidade; apresentando um projeto pessoal temático bem construído, porém de forma esperada.\n2.0 – Aproveitamento ótimo de todos os recursos estruturais de texto e desenvolvimento dos elementos constituintes do gênero como boa paragrafação, simetria entre os parágrafos, número de linhas suficientes para explicar as ideias, o tema sempre presenta nos parágrafos."
                    );
                  } else {
                    setComment2(
                      a.comment +
                        "– ADEQUAÇÃO AO TEMA\nZero – Foge completamente ao tema.   \n0.5 – Explora o assunto e aborda superficialmente o tema; parafraseando os textos da proposta.\n1.0 – Aborda parcialmente o tema, em que o tema não é retomado em todos os parágrafos.\n1.5 – Aborda adequadamente; fugiu da paráfrase e trouxe ideias da coletânea sem cópia.\n2.0 – Contribuição pessoal, informatividade: algo inusitado (ideia inovadora).\n\n- ADEQUAÇÃO AO TIPO DE COMPOSIÇÃO (GÊNERO)\nZero – Foge completamente ao tipo de composição.	\n0.5 – Foge parcialmente (mistura de modalidades textuais como ora opinião e ora desabafo)\n1.0 – Atende ao tipo, mas apresenta falha na estrutura (argumentação sem a separação de causa e consequência ou conclusão que não atende a proposta de intervenção ou uma reflexão).\n1.5 – Atende ao tipo, mas não explora e/ou utiliza todos os recursos da modalidade; apresentando um projeto pessoal temático bem construído, porém de forma esperada.\n2.0 – Aproveitamento ótimo de todos os recursos estruturais de texto e desenvolvimento dos elementos constituintes do gênero como boa paragrafação, simetria entre os parágrafos, número de linhas suficientes para explicar as ideias, o tema sempre presenta nos parágrafos."
                    );
                  }
                } else {
                  if (a.comment != "") {
                    setComment2(a.comment);
                  }
                  // else if(query.get("groupId") == "FfwUAMAF6T" || query.get("groupId") == "jRub8YU8yE" || query.get("groupId")== "nmgdhzmq4z" || query.get("groupId") == "e03Q9Utgg5" || query.get("groupId")== "HsKXbsqYP6" || query.get("groupId")=="jw1Mtnpf4V"){
                  //   setComment2(a.comment + "COMPETÊNCIA 02\n200 pontos: Você desenvolve o tema por meio de argumentação consistente, a partir de um repertório sociocultural produtivo e apresenta excelente domínio do texto argumentativo. Portanto, você atingiu 100% da nota.\n160 pontos: Você desenvolve o tema por meio de argumentação consistente e apresenta bom domínio do texto argumentativo, com proposição, argumentação e conclusão. Portanto, você atingiu 80% da nota.\n120 pontos: Você desenvolve o tema por meio de argumentação previsível e apresenta domínio mediano do texto argumentativo, com proposição, argumentação e conclusão. Portanto, você atingiu 60% da nota, pois o tema é desenvolvido claramente, porém foi escrito com base no senso comum e apresenta problema de progressão.\n80 pontos: Você desenvolve o tema recorrendo à cópia de trechos dos textos motivadores ou apresenta domínio insuficiente do texto argumentativo, não atendendo à estrutura com proposição, argumentação e conclusão. Portanto, você atingiu 40% da nota.\n40 pontos: Você apresenta o assunto, tangenciando o tema, ou demonstra domínio precário do texto argumentativo, com traços constantes de outros tipos textuais. Portanto, você atingiu 20% da nota.\n0 ponto: Fuga ao tema/não atendimento à estrutura argumentativa. Nestes casos a sua redação recebe nota zero e é anulada.")
                  // }
                  else {
                    setComment2(
                      a.comment +
                        "– ADEQUAÇÃO AO TEMA\nZero – Foge completamente ao tema.   \n0.5 – Explora o assunto e aborda superficialmente o tema; parafraseando os textos da proposta.\n1.0 – Aborda parcialmente o tema, em que o tema não é retomado em todos os parágrafos.\n1.5 – Aborda adequadamente; fugiu da paráfrase e trouxe ideias da coletânea sem cópia.\n2.0 – Contribuição pessoal, informatividade: algo inusitado (ideia inovadora).\n\n- ADEQUAÇÃO AO TIPO DE COMPOSIÇÃO (GÊNERO)\nZero – Foge completamente ao tipo de composição.	\n0.5 – Foge parcialmente (mistura de modalidades textuais como ora opinião e ora desabafo)\n1.0 – Atende ao tipo, mas apresenta falha na estrutura (argumentação sem a separação de causa e consequência ou conclusão que não atende a proposta de intervenção ou uma reflexão).\n1.5 – Atende ao tipo, mas não explora e/ou utiliza todos os recursos da modalidade; apresentando um projeto pessoal temático bem construído, porém de forma esperada.\n2.0 – Aproveitamento ótimo de todos os recursos estruturais de texto e desenvolvimento dos elementos constituintes do gênero como boa paragrafação, simetria entre os parágrafos, número de linhas suficientes para explicar as ideias, o tema sempre presenta nos parágrafos."
                    );
                  }
                }
                break;
              case 3:
                // setSlider3(a.grade ? a.grade.grade : 0);
                // if(a.comment != ""){
                //   setComment3(a.comment);
                // }
                // else{
                // setComment3(a.comment + "–PROJETO DE TEXTO\nZero – É composto de frases soltas (rejeita as conjunções e apresenta ideias soltas). Sem referência à coletânea. Não apresenta uma tese.\n0.5 – Apresenta muitas falhas de encadeamento (repetição excessiva de itens, inconsistências constantes de ideias, um parágrafo sem relação com o outro) e há pouca relação com a coletânea. Não apresenta a tese específica.\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (clareza do texto) bem como cita a coletânea, porém, com pouco trabalho de relacionar o sentido dos parágrafos com as ideias sugeridas pela coletânea.\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias. Bom uso da coletânea, mas sem muita contribuição pessoal.\n2.0 – Excelente uso da coletânea. Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos e apresenta contribuição pessoal.");
                // }
                // break;
                setSlider3(a.grade ? a.grade.grade : 0);
                if (query.get("oid") == "0TsXrUs") {
                  if (a.comment != "") {
                    setComment3(a.comment);
                  } else {
                    setComment3(
                      a.comment +
                        "–PROJETO DE TEXTO\nZero – É composto de frases soltas (rejeita as conjunções e apresenta ideias soltas). Sem referência à coletânea. Não apresenta uma tese.\n0.5 – Apresenta muitas falhas de encadeamento (repetição excessiva de itens, inconsistências constantes de ideias, um parágrafo sem relação com o outro) e há pouca relação com a coletânea. Não apresenta a tese específica.\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (clareza do texto) bem como cita a coletânea, porém, com pouco trabalho de relacionar o sentido dos parágrafos com as ideias sugeridas pela coletânea.\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias. Bom uso da coletânea, mas sem muita contribuição pessoal.\n2.0 – Excelente uso da coletânea. Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos e apresenta contribuição pessoal."
                    );
                  }
                  break;
                } else {
                  if (a.comment != "") {
                    setComment3(a.comment);
                  } else {
                    setComment3(
                      a.comment +
                        "–PROJETO DE TEXTO\nZero – É composto de frases soltas (rejeita as conjunções e apresenta ideias soltas). Sem referência à coletânea. Não apresenta uma tese.\n 0.5 – Apresenta muitas falhas de encadeamento (repetição excessiva de itens, inconsistências constantes de ideias, um parágrafo sem relação com o outro) e há pouca relação com a coletânea. Não apresenta a tese específica.\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (clareza do texto) bem como cita a coletânea, porém, com pouco trabalho de relacionar o sentido dos parágrafos com as ideias sugeridas pela coletânea.\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias. Bom uso da coletânea, mas sem muita contribuição pessoal.\n2.0 – Excelente uso da coletânea. Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos e apresenta contribuição pessoal."
                    );
                  }
                }
                break;
              case 4:
                // setSlider4(a.grade ? a.grade.grade : 0);
                // if(a.comment != ""){
                //   setComment4(a.comment);
                // }else{
                // setComment4(a.comment + "– COESÃO \nZero – É composto de frases soltas (rejeita as conjunções e ideais soltas) e mau uso dos pronomes e de sinônimos. \n0.5 – Apresenta muitas falhas de encadeamento que faz com que haja desorganização das informações e muita repetição de palavras e de ideias\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (mas ao invés de todavia; assim ao invés de por conseguinte, etc)\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias, pouquíssima repetição de palavras e de ideias.\n2.0 –Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos.\n\n– COERÊNCIA\nZero – Incoerência por: falta de adequação ao tema; falta de coesão entre as ideias; falta de adequação à realidade.\n0.5 – Pouca coerência interna (ideias contraditórias, confusas, ideias ambíguas, quebra de relação entre os pensamentos). \n1.0 – Coerência interna, mas pouca adequação à realidade. \n1.5 – Coerência interna, mas falhas em progressão.\n2.0 – Coerência absoluta (externa e interna).");
                // }
                // break;
                setSlider4(a.grade ? a.grade.grade : 0);
                if (query.get("oid") == "0TsXrUs") {
                  if (a.comment != "") {
                    setComment4(a.comment);
                  } else {
                    setComment4(
                      a.comment +
                        "– COESÃO \nZero – É composto de frases soltas (rejeita as conjunções e ideais soltas) e mau uso dos pronomes e de sinônimos. \n0.5 – Apresenta muitas falhas de encadeamento que faz com que haja desorganização das informações e muita repetição de palavras e de ideias\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (mas ao invés de todavia; assim ao invés de por conseguinte, etc)\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias, pouquíssima repetição de palavras e de ideias.\n2.0 –Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos.\n\n– COERÊNCIA\nZero – Incoerência por: falta de adequação ao tema; falta de coesão entre as ideias; falta de adequação à realidade.\n0.5 – Pouca coerência interna (ideias contraditórias, confusas, ideias ambíguas, quebra de relação entre os pensamentos). \n1.0 – Coerência interna, mas pouca adequação à realidade. \n1.5 – Coerência interna, mas falhas em progressão.\n2.0 – Coerência absoluta (externa e interna)."
                    );
                  }
                } else {
                  if (a.comment != "") {
                    setComment4(a.comment);
                  } else {
                    setComment4(
                      a.comment +
                        "– COESÃO \nZero – É composto de frases soltas (rejeita as conjunções e ideais soltas) e mau uso dos pronomes e de sinônimos. \n0.5 – Apresenta muitas falhas de encadeamento que faz com que haja desorganização das informações e muita repetição de palavras e de ideias\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (mas ao invés de todavia; assim ao invés de por conseguinte, etc)\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias, pouquíssima repetição de palavras e de ideias.\n2.0 –Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos.\n– COERÊNCIA\nZero – Incoerência por: falta de adequação ao tema; falta de coesão entre as ideias; falta de adequação à realidade.\n0.5 – Pouca coerência interna (ideias contraditórias, confusas, ideias ambíguas, quebra de relação entre os pensamentos). \n1.0 – Coerência interna, mas pouca adequação à realidade. \n1.5 – Coerência interna, mas falhas em progressão.\n2.0 – Coerência absoluta (externa e interna)."
                    );
                  }
                }
                break;
              case 5:
                // setSlider5(a.grade ? a.grade.grade : 0);
                // if(a.comment != ""){
                //   setComment5(a.comment);
                // }else{
                // setComment5(a.comment + "-PROPOSTA DE INTERVENÇÃO/ CONCLUSÃO\nZero – Não apresenta o problema, a instituição responsável por amenizar o problema e uma ideia de como amenizar o problema. Não houve uma reflexão sobre o problema\n0.5 – De três itens essenciais da proposta de intervenção, o texto apresenta apenas um. Não houve retomada da introdução e reflexão sobre o tema.\n1.0 – De três itens essenciais da proposta de intervenção, o texto apresenta dois. Além disso, foi retomado o tema, mas não a introdução.\n1.5 – De três itens essenciais da proposta de intervenção, o texto apresenta todos bem como reflexão e retomada da introdução.\n2.0 – O texto apresenta EXCELENTE REFLEXÃO FILOSÓFICA E os três itens exigidos na proposta de intervenção e atende o método de proposta específica, mensurável, atingível, relevante e temática.");
                // }
                // break;
                setSlider5(a.grade ? a.grade.grade : 0);
                if (query.get("oid") == "0TsXrUs") {
                  if (a.comment != "") {
                    setComment5(a.comment);
                  } else {
                    setComment5(
                      a.comment +
                        "-PROPOSTA DE INTERVENÇÃO/ CONCLUSÃO\nZero – Não apresenta o problema, a instituição responsável por amenizar o problema e uma ideia de como amenizar o problema. Não houve uma reflexão sobre o problema\n0.5 – De três itens essenciais da proposta de intervenção, o texto apresenta apenas um. Não houve retomada da introdução e reflexão sobre o tema.\n1.0 – De três itens essenciais da proposta de intervenção, o texto apresenta dois. Além disso, foi retomado o tema, mas não a introdução.\n1.5 – De três itens essenciais da proposta de intervenção, o texto apresenta todos bem como reflexão e retomada da introdução.\n2.0 – O texto apresenta EXCELENTE REFLEXÃO FILOSÓFICA E os três itens exigidos na proposta de intervenção e atende o método de proposta específica, mensurável, atingível, relevante e temática."
                    );
                  }
                } else {
                  if (a.comment != "") {
                    setComment5(a.comment);
                  } else {
                    setComment5(
                      a.comment +
                        "-PROPOSTA DE INTERVENÇÃO/ CONCLUSÃO\nZero – Não retoma o problema, se foi feita proposta de intervenção não foi citada a instituição responsável por amenizar o problema e uma ideia de como amenizar o problema. Não houve uma reflexão sobre o problema\n0.5 – De três itens essenciais da proposta de intervenção, o texto apresenta apenas um. Não houve retomada da introdução e reflexão sobre o tema.\n1.0 – De três itens essenciais da proposta de intervenção, o texto apresenta dois. Além disso, foi retomado o tema, mas não a introdução.\n1.5 – De três itens essenciais da proposta de intervenção, o texto apresenta todos bem como reflexão e retomada da introdução.\n2.0 – O texto apresenta EXCELENTE REFLEXÃO FILOSÓFICA E os três itens exigidos na proposta de intervenção e atende o método de proposta específica, mensurável, atingível, relevante e temática."
                    );
                  }
                }
                break;
            }
          });

          setAssignments(array);
        }
      };
      {
        query.get("year")
          ? xhr2.open(
              "GET",
              baseURL +
                "o/" +
                query.get("oid") +
                "/groups/" +
                query.get("groupId") +
                "/texts/" +
                query.get("textId") +
                "/users/" +
                query.get("correctionId") +
                "/corrections/" +
                query.get("correctorId") +
                "/assignments/" +
                query.get("year"),
              true
            )
          : xhr2.open(
              "GET",
              baseURL +
                "o/" +
                query.get("oid") +
                "/groups/" +
                query.get("groupId") +
                "/texts/" +
                query.get("textId") +
                "/users/" +
                query.get("correctionId") +
                "/corrections/" +
                query.get("correctorId") +
                "/assignments",
              true
            );
      }
      xhr2.setRequestHeader("authorization", "Bearer " + query.get("tka"));
      xhr2.send();

      //

      var xhr3 = new XMLHttpRequest();
      xhr3.onreadystatechange = function () {
        if (xhr3.readyState === 4) {
          var zoom = essayZoom / 100;
          var x = JSON.parse(xhr3.response) || [];
          x = x.map(function (c) {
            c.top = (c.positions.top * ratio) / zoom;
            c.left = (c.positions.left * ratio) / zoom;
            c.width = (c.positions.width * ratio) / zoom;
            c.height = (c.positions.height * ratio) / zoom;
            c.originalHeight = c.positions.height;
            c.originalLeft = c.positions.left;
            c.originalWidth = c.positions.width;
            c.originalTop = c.positions.top;
            c.text = c.comment;
            c.id = parseInt(c.id);
            if (c.sticker) {
              c.type = "sticker";
            } else {
              if (!(c.type == "commentArea" || c.type == "sticker")) {
                c.type = "commentArea";
              }
              c.colors = c.style;
            }
            return c;
          });
          setElements(x);
        }
      };
      {
        query.get("year")
          ? xhr3.open(
              "GET",
              baseURL +
                "o/" +
                query.get("oid") +
                "/groups/" +
                query.get("groupId") +
                "/texts/" +
                query.get("textId") +
                "/users/" +
                query.get("correctionId") +
                "/corrections/" +
                query.get("correctorId") +
                "/comments/" +
                query.get("year"),
              true
            )
          : xhr3.open(
              "GET",
              baseURL +
                "o/" +
                query.get("oid") +
                "/groups/" +
                query.get("groupId") +
                "/texts/" +
                query.get("textId") +
                "/users/" +
                query.get("correctionId") +
                "/corrections/" +
                query.get("correctorId") +
                "/comments",
              true
            );
      }
      xhr3.setRequestHeader("authorization", "Bearer " + query.get("tka"));
      xhr3.send();
    } else {
      VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
        xhrUser.setRequestHeader("authorization", "Bearer " + tokenA);
        xhrUser.addEventListener("load", () => {
          setUser(JSON.parse(xhrUser.responseText));
        });
        xhrUser.send();
        var xhr = new XMLHttpRequest();
        xhr.addEventListener("load", () => {
          setTranscriptionLenght(
            JSON.parse(xhr.responseText).transcription.length
          );
          setTranscription(
            JSON.parse(xhr.responseText).transcription
              ? JSON.parse(xhr.responseText).transcription
              : ""
          );
          setTextURL(JSON.parse(xhr.responseText).photoURL);
          setTimeout(function () {
            setImageDimensions({
              width: document.getElementById("essay").naturalWidth,
              height: document.getElementById("essay").naturalHeight,
            });
            setShowingLoad(false);
          }, 2000);
        });
        if (query.get("year") != null) {
          xhr.open(
            "GET",
            baseURL +
              "o/" +
              query.get("oid") +
              "/groups/" +
              query.get("groupId") +
              "/texts/" +
              query.get("textId") +
              "/users/" +
              query.get("correctionId") +
              "/corrections/photourl/" +
              query.get("year"),
            true
          );
        } else {
          xhr.open(
            "GET",
            baseURL +
              "o/" +
              query.get("oid") +
              "/groups/" +
              query.get("groupId") +
              "/texts/" +
              query.get("textId") +
              "/users/" +
              query.get("correctionId") +
              "/corrections/photourl",
            true
          );
        }
        // xhr.open("GET", baseURL+'o/'+query.get("oid")+'/groups/'+query.get("groupId")+'/texts/'+query.get("textId")+'/users/'+query.get("correctionId")+'/corrections/text', true);
        xhr.setRequestHeader("authorization", "Bearer " + tokenA);
        xhr.send();

        //

        var xhr2 = new XMLHttpRequest();
        xhr2.onreadystatechange = function () {
          if (xhr2.readyState === 4) {
            var x = JSON.parse(xhr2.responseText).criteria;
            var final = JSON.parse(xhr2.responseText).config.final;
            var config = JSON.parse(xhr2.responseText).config;
            if (config) {
              setGradeConfig(config.grade);
            }
            if (query.get("oid") == "0TsXrUs") {
              if (final) {
                setFinal(true);
              }
            } else {
              if (final) {
                setFinalComment(
                  "Olá, tudo bem?Obrigado pelo envio. No relatório final, colocamos uma grade de notas para que você entenda como é avaliado cada critério e isso justifica sua nota final. Assim, finalizamos o segundo trimestre. Estamos sempre à disposição. Equipe Grafiame."
                );
                setFinal(true);
              }
            }

            var array = [{}, {}, {}, {}, {}];
            array = array.map(function (a, index) {
              if (x[index]) {
                return x[index];
              } else {
                return null;
              }
            });

            array = array.filter(function (a) {
              return a;
            });

            array.forEach(function (a, index) {
              var i = index + 1;
              switch (i) {
                case 1:
                  // setSlider1(a.grade ? a.grade.grade : 0);
                  // if(a.comment != ""){
                  //   setComment1(a.comment);
                  // }
                  // else{
                  //   setComment1(a.comment + "- ADEQUAÇÃO AO NÍVEL DE LINGUAGEM\nZero – Insuficiência vocabular e erros gramaticais sérios que comprometem o entendimento o entendimento do texto. Para a refeitura, é necessário reestruturar toda a linguagem.\n0.5 – Variedade vocabular insuficiente, interferências da oralidade, algumas incorreções gramaticais. Para a refeitura, corrija sua redação para que esses erros sejam sanados\n1.0 – Apresenta variedade vocabular, mas alguns desvios recorrentes no uso das regras gramaticais (acentuação, pontuação, ortografia, plural, etc).\n1.5 – Adequação vocabular e gramatical, bom domínio das regras normativas da língua escrita. Para a refeitura, reveja a possibilidade de construções criativas das frases.\n2.0 – Aproveitamento ótimo de todos os recursos da Língua Portuguesa, criatividade na construção das frases que são originais e bem elaboradas.");
                  // }
                  // break;
                  setSlider1(a.grade ? a.grade.grade : 0);
                  if (query.get("oid") == "0TsXrUs") {
                    if (a.comment != "") {
                      setComment1(a.comment);
                    } else {
                      setComment1(
                        a.comment +
                          "- ADEQUAÇÃO AO NÍVEL DE LINGUAGEM\nZero – Insuficiência vocabular e erros gramaticais sérios que comprometem o entendimento o entendimento do texto. Para a refeitura, é necessário reestruturar toda a linguagem.\n0.5 – Variedade vocabular insuficiente, interferências da oralidade, algumas incorreções gramaticais. Para a refeitura, corrija sua redação para que esses erros sejam sanados\n1.0 – Apresenta variedade vocabular, mas alguns desvios recorrentes no uso das regras gramaticais (acentuação, pontuação, ortografia, plural, etc).\n1.5 – Adequação vocabular e gramatical, bom domínio das regras normativas da língua escrita. Para a refeitura, reveja a possibilidade de construções criativas das frases.\n2.0 – Aproveitamento ótimo de todos os recursos da Língua Portuguesa, criatividade na construção das frases que são originais e bem elaboradas."
                      );
                    }
                  } else {
                    if (a.comment != "") {
                      setComment1(a.comment);
                    } else {
                      setComment1(
                        a.comment +
                          "- ADEQUAÇÃO AO NÍVEL DE LINGUAGEM\nZero – Insuficiência vocabular e erros gramaticais sérios que comprometem o entendimento o entendimento do texto. Para a refeitura, é necessário reestruturar toda a linguagem.\n0.5 – Variedade vocabular insuficiente, interferências da oralidade, algumas incorreções gramaticais. Para a refeitura, corrija sua redação para que esses erros sejam sanados\n1.0 – Apresenta variedade vocabular, mas alguns desvios recorrentes no uso das regras gramaticais (acentuação, pontuação, ortografia, plural, etc).\n1.5 – Adequação vocabular e gramatical, bom domínio das regras normativas da língua escrita. Para a refeitura, reveja a possibilidade de construções criativas das frases.\n2.0 – Aproveitamento ótimo de todos os recursos da Língua Portuguesa, criatividade na construção das frases que são originais e bem elaboradas."
                      );
                    }
                  }
                  break;
                case 2:
                  // setSlider2(a.grade ? a.grade.grade : 0);
                  // if(a.comment != ""){
                  //   setComment2(a.comment);
                  // } else if(query.get("groupId") == "FfwUAMAF6T" || query.get("groupId") == "jRub8YU8yE" || query.get("groupId")== "nmgdhzmq4z" || query.get("groupId") == "e03Q9Utgg5" || query.get("groupId")== "HsKXbsqYP6" || query.get("groupId")=="jw1Mtnpf4V"){
                  //   setComment2(a.comment + "– ADEQUAÇÃO AO TEMA\nZero – Foge completamente ao tema.   \n0.5 – Explora o assunto e aborda superficialmente o tema; parafraseando os textos da proposta.\n1.0 – Aborda parcialmente o tema, em que o tema não é retomado em todos os parágrafos.\n1.5 – Aborda adequadamente; fugiu da paráfrase e trouxe ideias da coletânea sem cópia.\n2.0 – Contribuição pessoal, informatividade: algo inusitado (ideia inovadora).\n\n- ADEQUAÇÃO AO TIPO DE COMPOSIÇÃO (GÊNERO)\nZero – Foge completamente ao tipo de composição.	\n0.5 – Foge parcialmente (mistura de modalidades textuais como ora opinião e ora desabafo)\n1.0 – Atende ao tipo, mas apresenta falha na estrutura (argumentação sem a separação de causa e consequência ou conclusão que não atende a proposta de intervenção ou uma reflexão).\n1.5 – Atende ao tipo, mas não explora e/ou utiliza todos os recursos da modalidade; apresentando um projeto pessoal temático bem construído, porém de forma esperada.\n2.0 – Aproveitamento ótimo de todos os recursos estruturais de texto e desenvolvimento dos elementos constituintes do gênero como boa paragrafação, simetria entre os parágrafos, número de linhas suficientes para explicar as ideias, o tema sempre presenta nos parágrafos.")
                  // }else{
                  // setComment2(a.comment + "– ADEQUAÇÃO AO TEMA\nZero – Foge completamente ao tema.   \n0.5 – Explora o assunto e aborda superficialmente o tema; parafraseando os textos da proposta.\n1.0 – Aborda parcialmente o tema, em que o tema não é retomado em todos os parágrafos.\n1.5 – Aborda adequadamente; fugiu da paráfrase e trouxe ideias da coletânea sem cópia.\n2.0 – Contribuição pessoal, informatividade: algo inusitado (ideia inovadora).\n\n- ADEQUAÇÃO AO TIPO DE COMPOSIÇÃO (GÊNERO)\nZero – Foge completamente ao tipo de composição.	\n0.5 – Foge parcialmente (mistura de modalidades textuais como ora opinião e ora desabafo)\n1.0 – Atende ao tipo, mas apresenta falha na estrutura (argumentação sem a separação de causa e consequência ou conclusão que não atende a proposta de intervenção ou uma reflexão).\n1.5 – Atende ao tipo, mas não explora e/ou utiliza todos os recursos da modalidade; apresentando um projeto pessoal temático bem construído, porém de forma esperada.\n2.0 – Aproveitamento ótimo de todos os recursos estruturais de texto e desenvolvimento dos elementos constituintes do gênero como boa paragrafação, simetria entre os parágrafos, número de linhas suficientes para explicar as ideias, o tema sempre presenta nos parágrafos.");
                  // }
                  // break;
                  setSlider2(a.grade ? a.grade.grade : 0);
                  if (query.get("oid") == "0TsXrUs") {
                    if (a.comment != "") {
                      setComment2(a.comment);
                    } else if (
                      query.get("groupId") == "FfwUAMAF6T" ||
                      query.get("groupId") == "jRub8YU8yE" ||
                      query.get("groupId") == "nmgdhzmq4z" ||
                      query.get("groupId") == "e03Q9Utgg5" ||
                      query.get("groupId") == "HsKXbsqYP6" ||
                      query.get("groupId") == "jw1Mtnpf4V"
                    ) {
                      setComment2(
                        a.comment +
                          "– ADEQUAÇÃO AO TEMA\nZero – Foge completamente ao tema.   \n0.5 – Explora o assunto e aborda superficialmente o tema; parafraseando os textos da proposta.\n1.0 – Aborda parcialmente o tema, em que o tema não é retomado em todos os parágrafos.\n1.5 – Aborda adequadamente; fugiu da paráfrase e trouxe ideias da coletânea sem cópia.\n2.0 – Contribuição pessoal, informatividade: algo inusitado (ideia inovadora).\n\n- ADEQUAÇÃO AO TIPO DE COMPOSIÇÃO (GÊNERO)\nZero – Foge completamente ao tipo de composição.	\n0.5 – Foge parcialmente (mistura de modalidades textuais como ora opinião e ora desabafo)\n1.0 – Atende ao tipo, mas apresenta falha na estrutura (argumentação sem a separação de causa e consequência ou conclusão que não atende a proposta de intervenção ou uma reflexão).\n1.5 – Atende ao tipo, mas não explora e/ou utiliza todos os recursos da modalidade; apresentando um projeto pessoal temático bem construído, porém de forma esperada.\n2.0 – Aproveitamento ótimo de todos os recursos estruturais de texto e desenvolvimento dos elementos constituintes do gênero como boa paragrafação, simetria entre os parágrafos, número de linhas suficientes para explicar as ideias, o tema sempre presenta nos parágrafos."
                      );
                    } else {
                      setComment2(
                        a.comment +
                          "– ADEQUAÇÃO AO TEMA\nZero – Foge completamente ao tema.   \n0.5 – Explora o assunto e aborda superficialmente o tema; parafraseando os textos da proposta.\n1.0 – Aborda parcialmente o tema, em que o tema não é retomado em todos os parágrafos.\n1.5 – Aborda adequadamente; fugiu da paráfrase e trouxe ideias da coletânea sem cópia.\n2.0 – Contribuição pessoal, informatividade: algo inusitado (ideia inovadora).\n\n- ADEQUAÇÃO AO TIPO DE COMPOSIÇÃO (GÊNERO)\nZero – Foge completamente ao tipo de composição.	\n0.5 – Foge parcialmente (mistura de modalidades textuais como ora opinião e ora desabafo)\n1.0 – Atende ao tipo, mas apresenta falha na estrutura (argumentação sem a separação de causa e consequência ou conclusão que não atende a proposta de intervenção ou uma reflexão).\n1.5 – Atende ao tipo, mas não explora e/ou utiliza todos os recursos da modalidade; apresentando um projeto pessoal temático bem construído, porém de forma esperada.\n2.0 – Aproveitamento ótimo de todos os recursos estruturais de texto e desenvolvimento dos elementos constituintes do gênero como boa paragrafação, simetria entre os parágrafos, número de linhas suficientes para explicar as ideias, o tema sempre presenta nos parágrafos."
                      );
                    }
                  } else {
                    if (a.comment != "") {
                      setComment2(a.comment);
                    }
                    // else if(query.get("groupId") == "FfwUAMAF6T" || query.get("groupId") == "jRub8YU8yE" || query.get("groupId")== "nmgdhzmq4z" || query.get("groupId") == "e03Q9Utgg5" || query.get("groupId")== "HsKXbsqYP6" || query.get("groupId")=="jw1Mtnpf4V"){
                    //   setComment2(a.comment + "COMPETÊNCIA 02\n200 pontos: Você desenvolve o tema por meio de argumentação consistente, a partir de um repertório sociocultural produtivo e apresenta excelente domínio do texto argumentativo. Portanto, você atingiu 100% da nota.\n160 pontos: Você desenvolve o tema por meio de argumentação consistente e apresenta bom domínio do texto argumentativo, com proposição, argumentação e conclusão. Portanto, você atingiu 80% da nota.\n120 pontos: Você desenvolve o tema por meio de argumentação previsível e apresenta domínio mediano do texto argumentativo, com proposição, argumentação e conclusão. Portanto, você atingiu 60% da nota, pois o tema é desenvolvido claramente, porém foi escrito com base no senso comum e apresenta problema de progressão.\n80 pontos: Você desenvolve o tema recorrendo à cópia de trechos dos textos motivadores ou apresenta domínio insuficiente do texto argumentativo, não atendendo à estrutura com proposição, argumentação e conclusão. Portanto, você atingiu 40% da nota.\n40 pontos: Você apresenta o assunto, tangenciando o tema, ou demonstra domínio precário do texto argumentativo, com traços constantes de outros tipos textuais. Portanto, você atingiu 20% da nota.\n0 ponto: Fuga ao tema/não atendimento à estrutura argumentativa. Nestes casos a sua redação recebe nota zero e é anulada.")
                    // }
                    else {
                      setComment2(
                        a.comment +
                          "– ADEQUAÇÃO AO TEMA\nZero – Foge completamente ao tema.   \n0.5 – Explora o assunto e aborda superficialmente o tema; parafraseando os textos da proposta.\n1.0 – Aborda parcialmente o tema, em que o tema não é retomado em todos os parágrafos.\n1.5 – Aborda adequadamente; fugiu da paráfrase e trouxe ideias da coletânea sem cópia.\n2.0 – Contribuição pessoal, informatividade: algo inusitado (ideia inovadora).\n\n- ADEQUAÇÃO AO TIPO DE COMPOSIÇÃO (GÊNERO)\nZero – Foge completamente ao tipo de composição.	\n0.5 – Foge parcialmente (mistura de modalidades textuais como ora opinião e ora desabafo)\n1.0 – Atende ao tipo, mas apresenta falha na estrutura (argumentação sem a separação de causa e consequência ou conclusão que não atende a proposta de intervenção ou uma reflexão).\n1.5 – Atende ao tipo, mas não explora e/ou utiliza todos os recursos da modalidade; apresentando um projeto pessoal temático bem construído, porém de forma esperada.\n2.0 – Aproveitamento ótimo de todos os recursos estruturais de texto e desenvolvimento dos elementos constituintes do gênero como boa paragrafação, simetria entre os parágrafos, número de linhas suficientes para explicar as ideias, o tema sempre presenta nos parágrafos."
                      );
                    }
                  }
                  break;
                case 3:
                  // setSlider3(a.grade ? a.grade.grade : 0);
                  // if(a.comment != ""){
                  //   setComment3(a.comment);
                  // }
                  // else{
                  // setComment3(a.comment + "–PROJETO DE TEXTO\nZero – É composto de frases soltas (rejeita as conjunções e apresenta ideias soltas). Sem referência à coletânea. Não apresenta uma tese.\n0.5 – Apresenta muitas falhas de encadeamento (repetição excessiva de itens, inconsistências constantes de ideias, um parágrafo sem relação com o outro) e há pouca relação com a coletânea. Não apresenta a tese específica.\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (clareza do texto) bem como cita a coletânea, porém, com pouco trabalho de relacionar o sentido dos parágrafos com as ideias sugeridas pela coletânea.\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias. Bom uso da coletânea, mas sem muita contribuição pessoal.\n2.0 – Excelente uso da coletânea. Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos e apresenta contribuição pessoal.");
                  // }
                  // break;
                  setSlider3(a.grade ? a.grade.grade : 0);
                  if (query.get("oid") == "0TsXrUs") {
                    if (a.comment != "") {
                      setComment3(a.comment);
                    } else {
                      setComment3(
                        a.comment +
                          "–PROJETO DE TEXTO\nZero – É composto de frases soltas (rejeita as conjunções e apresenta ideias soltas). Sem referência à coletânea. Não apresenta uma tese.\n0.5 – Apresenta muitas falhas de encadeamento (repetição excessiva de itens, inconsistências constantes de ideias, um parágrafo sem relação com o outro) e há pouca relação com a coletânea. Não apresenta a tese específica.\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (clareza do texto) bem como cita a coletânea, porém, com pouco trabalho de relacionar o sentido dos parágrafos com as ideias sugeridas pela coletânea.\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias. Bom uso da coletânea, mas sem muita contribuição pessoal.\n2.0 – Excelente uso da coletânea. Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos e apresenta contribuição pessoal."
                      );
                    }
                    break;
                  } else {
                    if (a.comment != "") {
                      setComment3(a.comment);
                    } else {
                      setComment3(
                        a.comment +
                          "–PROJETO DE TEXTO\nZero – É composto de frases soltas (rejeita as conjunções e apresenta ideias soltas). Sem referência à coletânea. Não apresenta uma tese.\n 0.5 – Apresenta muitas falhas de encadeamento (repetição excessiva de itens, inconsistências constantes de ideias, um parágrafo sem relação com o outro) e há pouca relação com a coletânea. Não apresenta a tese específica.\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (clareza do texto) bem como cita a coletânea, porém, com pouco trabalho de relacionar o sentido dos parágrafos com as ideias sugeridas pela coletânea.\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias. Bom uso da coletânea, mas sem muita contribuição pessoal.\n2.0 – Excelente uso da coletânea. Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos e apresenta contribuição pessoal."
                      );
                    }
                  }
                  break;
                case 4:
                  // setSlider4(a.grade ? a.grade.grade : 0);
                  // if(a.comment != ""){
                  //   setComment4(a.comment);
                  // }else{
                  // setComment4(a.comment + "– COESÃO \nZero – É composto de frases soltas (rejeita as conjunções e ideais soltas) e mau uso dos pronomes e de sinônimos. \n0.5 – Apresenta muitas falhas de encadeamento que faz com que haja desorganização das informações e muita repetição de palavras e de ideias\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (mas ao invés de todavia; assim ao invés de por conseguinte, etc)\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias, pouquíssima repetição de palavras e de ideias.\n2.0 –Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos.\n\n– COERÊNCIA\nZero – Incoerência por: falta de adequação ao tema; falta de coesão entre as ideias; falta de adequação à realidade.\n0.5 – Pouca coerência interna (ideias contraditórias, confusas, ideias ambíguas, quebra de relação entre os pensamentos). \n1.0 – Coerência interna, mas pouca adequação à realidade. \n1.5 – Coerência interna, mas falhas em progressão.\n2.0 – Coerência absoluta (externa e interna).");
                  // }
                  // break;
                  setSlider4(a.grade ? a.grade.grade : 0);
                  if (query.get("oid") == "0TsXrUs") {
                    if (a.comment != "") {
                      setComment4(a.comment);
                    } else {
                      setComment4(
                        a.comment +
                          "– COESÃO \nZero – É composto de frases soltas (rejeita as conjunções e ideais soltas) e mau uso dos pronomes e de sinônimos. \n0.5 – Apresenta muitas falhas de encadeamento que faz com que haja desorganização das informações e muita repetição de palavras e de ideias\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (mas ao invés de todavia; assim ao invés de por conseguinte, etc)\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias, pouquíssima repetição de palavras e de ideias.\n2.0 –Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos.\n\n– COERÊNCIA\nZero – Incoerência por: falta de adequação ao tema; falta de coesão entre as ideias; falta de adequação à realidade.\n0.5 – Pouca coerência interna (ideias contraditórias, confusas, ideias ambíguas, quebra de relação entre os pensamentos). \n1.0 – Coerência interna, mas pouca adequação à realidade. \n1.5 – Coerência interna, mas falhas em progressão.\n2.0 – Coerência absoluta (externa e interna)."
                      );
                    }
                  } else {
                    if (a.comment != "") {
                      setComment4(a.comment);
                    } else {
                      setComment4(
                        a.comment +
                          "– COESÃO \nZero – É composto de frases soltas (rejeita as conjunções e ideais soltas) e mau uso dos pronomes e de sinônimos. \n0.5 – Apresenta muitas falhas de encadeamento que faz com que haja desorganização das informações e muita repetição de palavras e de ideias\n1.0 – Não apresenta deficiências graves, entretanto mantém um domínio básico na utilização dos recursos coesivos (mas ao invés de todavia; assim ao invés de por conseguinte, etc)\n1.5 – Aproveita bom uso dos elementos coesivos e apresenta boa transição entre as ideias, pouquíssima repetição de palavras e de ideias.\n2.0 –Organiza muito bem as partes do texto e demonstra um ótimo domínio no uso dos recursos coesivos, apresentando boa transição entre os parágrafos.\n– COERÊNCIA\nZero – Incoerência por: falta de adequação ao tema; falta de coesão entre as ideias; falta de adequação à realidade.\n0.5 – Pouca coerência interna (ideias contraditórias, confusas, ideias ambíguas, quebra de relação entre os pensamentos). \n1.0 – Coerência interna, mas pouca adequação à realidade. \n1.5 – Coerência interna, mas falhas em progressão.\n2.0 – Coerência absoluta (externa e interna)."
                      );
                    }
                  }
                  break;
                case 5:
                  // setSlider5(a.grade ? a.grade.grade : 0);
                  // if(a.comment != ""){
                  //   setComment5(a.comment);
                  // }else{
                  // setComment5(a.comment + "-PROPOSTA DE INTERVENÇÃO/ CONCLUSÃO\nZero – Não apresenta o problema, a instituição responsável por amenizar o problema e uma ideia de como amenizar o problema. Não houve uma reflexão sobre o problema\n0.5 – De três itens essenciais da proposta de intervenção, o texto apresenta apenas um. Não houve retomada da introdução e reflexão sobre o tema.\n1.0 – De três itens essenciais da proposta de intervenção, o texto apresenta dois. Além disso, foi retomado o tema, mas não a introdução.\n1.5 – De três itens essenciais da proposta de intervenção, o texto apresenta todos bem como reflexão e retomada da introdução.\n2.0 – O texto apresenta EXCELENTE REFLEXÃO FILOSÓFICA E os três itens exigidos na proposta de intervenção e atende o método de proposta específica, mensurável, atingível, relevante e temática.");
                  // }
                  // break;
                  setSlider5(a.grade ? a.grade.grade : 0);
                  if (query.get("oid") == "0TsXrUs") {
                    if (a.comment != "") {
                      setComment5(a.comment);
                    } else {
                      setComment5(
                        a.comment +
                          "-PROPOSTA DE INTERVENÇÃO/ CONCLUSÃO\nZero – Não apresenta o problema, a instituição responsável por amenizar o problema e uma ideia de como amenizar o problema. Não houve uma reflexão sobre o problema\n0.5 – De três itens essenciais da proposta de intervenção, o texto apresenta apenas um. Não houve retomada da introdução e reflexão sobre o tema.\n1.0 – De três itens essenciais da proposta de intervenção, o texto apresenta dois. Além disso, foi retomado o tema, mas não a introdução.\n1.5 – De três itens essenciais da proposta de intervenção, o texto apresenta todos bem como reflexão e retomada da introdução.\n2.0 – O texto apresenta EXCELENTE REFLEXÃO FILOSÓFICA E os três itens exigidos na proposta de intervenção e atende o método de proposta específica, mensurável, atingível, relevante e temática."
                      );
                    }
                  } else {
                    if (a.comment != "") {
                      setComment5(a.comment);
                    } else {
                      setComment5(
                        a.comment +
                          "-PROPOSTA DE INTERVENÇÃO/ CONCLUSÃO\nZero – Não retoma o problema, se foi feita proposta de intervenção não foi citada a instituição responsável por amenizar o problema e uma ideia de como amenizar o problema. Não houve uma reflexão sobre o problema\n0.5 – De três itens essenciais da proposta de intervenção, o texto apresenta apenas um. Não houve retomada da introdução e reflexão sobre o tema.\n1.0 – De três itens essenciais da proposta de intervenção, o texto apresenta dois. Além disso, foi retomado o tema, mas não a introdução.\n1.5 – De três itens essenciais da proposta de intervenção, o texto apresenta todos bem como reflexão e retomada da introdução.\n2.0 – O texto apresenta EXCELENTE REFLEXÃO FILOSÓFICA E os três itens exigidos na proposta de intervenção e atende o método de proposta específica, mensurável, atingível, relevante e temática."
                      );
                    }
                  }
                  break;
              }
            });

            setAssignments(array);
          }
        };
        {
          query.get("year")
            ? xhr2.open(
                "GET",
                baseURL +
                  "o/" +
                  query.get("oid") +
                  "/groups/" +
                  query.get("groupId") +
                  "/texts/" +
                  query.get("textId") +
                  "/users/" +
                  query.get("correctionId") +
                  "/corrections/" +
                  query.get("correctorId") +
                  "/assignments/" +
                  query.get("year"),
                true
              )
            : xhr2.open(
                "GET",
                baseURL +
                  "o/" +
                  query.get("oid") +
                  "/groups/" +
                  query.get("groupId") +
                  "/texts/" +
                  query.get("textId") +
                  "/users/" +
                  query.get("correctionId") +
                  "/corrections/" +
                  query.get("correctorId") +
                  "/assignments",
                true
              );
        }
        xhr2.setRequestHeader("authorization", "Bearer " + tokenA);
        xhr2.send();

        //

        var xhr3 = new XMLHttpRequest();
        xhr3.onreadystatechange = function () {
          if (xhr3.readyState === 4) {
            var zoom = essayZoom / 100;
            var x = JSON.parse(xhr3.response) || [];
            x = x.map(function (c) {
              c.top = (c.positions.top * ratio) / zoom;
              c.left = (c.positions.left * ratio) / zoom;
              c.width = (c.positions.width * ratio) / zoom;
              c.height = (c.positions.height * ratio) / zoom;
              c.originalHeight = c.positions.height;
              c.originalLeft = c.positions.left;
              c.originalWidth = c.positions.width;
              c.originalTop = c.positions.top;
              c.text = c.comment;
              c.id = parseInt(c.id);
              if (c.sticker) {
                c.type = "sticker";
              } else {
                if (!(c.type == "commentArea" || c.type == "sticker")) {
                  c.type = "commentArea";
                }
                c.colors = c.style;
              }
              return c;
            });
            setElements(x);
          }
        };
        {
          query.get("year")
            ? xhr3.open(
                "GET",
                baseURL +
                  "o/" +
                  query.get("oid") +
                  "/groups/" +
                  query.get("groupId") +
                  "/texts/" +
                  query.get("textId") +
                  "/users/" +
                  query.get("correctionId") +
                  "/corrections/" +
                  query.get("correctorId") +
                  "/comments/" +
                  query.get("year"),
                true
              )
            : xhr3.open(
                "GET",
                baseURL +
                  "o/" +
                  query.get("oid") +
                  "/groups/" +
                  query.get("groupId") +
                  "/texts/" +
                  query.get("textId") +
                  "/users/" +
                  query.get("correctionId") +
                  "/corrections/" +
                  query.get("correctorId") +
                  "/comments",
                true
              );
        }
        xhr3.setRequestHeader("authorization", "Bearer " + tokenA);
        xhr3.send();
      });
    }
  }, []);

  function getStickerId(stickerId) {
    switch (stickerId) {
      case "interrogation":
        return 1;
      case "correct":
        return 2;
      case "like":
        return 3;
      case "wrong":
        return 4;
    }
  }

  function checkCache() {
    setTimeout(function () {
      var body = cachedElements.map(function (e) {
        var x = {
          id: e.id,
          positions: {
            top: e.originalTop,
            height: e.originalHeight,
            width: e.originalWidth,
            left: e.originalLeft,
          },
          type: e.type,
          comment: e.text,
        };
        if (e.type != "sticker") {
          x.style = e.colors;
        } else {
          x.sticker = getStickerId(e.sticker);
        }
        return x;
      });

      if (body.length > 0) {
        if (query.get("tka") != null) {
          var xhr4 = new XMLHttpRequest();
          xhr4.addEventListener("load", () => {});

          xhr4.open(
            "POST",
            baseURL +
              "o/" +
              query.get("oid") +
              "/groups/" +
              query.get("groupId") +
              "/texts/" +
              query.get("textId") +
              "/users/" +
              query.get("correctionId") +
              "/corrections/" +
              query.get("correctorId") +
              "/comments",
            true
          );
          xhr4.setRequestHeader("Content-Type", "application/json");
          xhr4.setRequestHeader("authorization", "Bearer " + query.get("tka"));
          xhr4.send(JSON.stringify(body));
        } else {
          VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
            var xhr4 = new XMLHttpRequest();
            xhr4.addEventListener("load", () => {});

            xhr4.open(
              "POST",
              baseURL +
                "o/" +
                query.get("oid") +
                "/groups/" +
                query.get("groupId") +
                "/texts/" +
                query.get("textId") +
                "/users/" +
                query.get("correctionId") +
                "/corrections/" +
                query.get("correctorId") +
                "/comments",
              true
            );
            xhr4.setRequestHeader("Content-Type", "application/json");
            xhr4.setRequestHeader("authorization", "Bearer " + tokenA);
            xhr4.send(JSON.stringify(body));
          });
        }
      }
    }, 100);
  }

  function handleClick(event) {
    if (selectedTool === "commentPin") {
      setElements([
        ...elements,
        {
          x: event.nativeEvent.offsetX,
          y: event.nativeEvent.offsetY,
          originalX: Math.round(event.nativeEvent.offsetX * ratio),
          originalY: Math.round(event.nativeEvent.offsetY * ratio),
        },
      ]);
    } else if (selectedTool === "sticker" && selectedSticker) {
      var highestId = 0;
      elements.forEach((el) => {
        if (highestId < el.id) {
          highestId = el.id;
        }
      });
      var aux = selectionArea;
      aux.id = highestId + 1;
      aux.type = "sticker";
      aux.sticker = selectedSticker;
      setElements([...elements, aux]);
      setCachedElements([...cachedElements, aux]);
      setSelectionArea({});
      setSelectedSticker(null);
    }
  }

  function handleMouseDown(event) {
    if (event.target.id === "essay") {
      setMouseDownPoint({
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY,
        screenX: event.screenX,
        screenY: event.screenY,
      });
      setSelectedElement(null);
      setMouseDown(true);
    } else if (event.target.id && event.target.id.indexOf("rect") != -1) {
      if (
        event.target.id.match(/\d+/) &&
        event.target.id.match(/\d+/)[0].length
      ) {
        setSelectedElement(event.target.id.match(/\d+/)[0]);
      }
    }
    setMoved(false);
  }

  function getQuickComment(id) {
    switch (id) {
      case 1:
        return "Crase";
      case 2:
        return "Pontuação";
      case 3:
        return "Ortografia";
      case 4:
        return "Concordância";
    }
  }

  function handleMouseUp(event) {
    if (hasMoved && selectedTool === "commentArea") {
      setMouseDownPoint({});
      if (selectionArea && selectionArea.width && selectionArea.top) {
        var highestId = 0;
        elements.forEach((el) => {
          if (highestId < el.id) {
            highestId = el.id;
          }
        });
        var aux = selectionArea;
        aux.id = highestId + 1;
        aux.type = "commentArea";
        if (quickComment) {
          aux.text = getQuickComment(quickComment);
          setQuickComment(null);
        }
        aux.colors = {
          backgroundColor: defaultBackgroundColor,
          borderColor: defaultBorderColor,
        };
        setElements([...elements, aux]);
        setCachedElements([...cachedElements, aux]);
        setSelectionArea({});
      }
    }
    setMouseDown(false);
  }

  function handleDragStart() {
    return false;
  }

  function handleMove(event) {
    setMoved(true);
    var top = mouseDownPoint.y;
    var left = mouseDownPoint.x;
    var zoom = essayZoom / 100;
    var originalTop = (top * ratio) / zoom;
    var originalLeft = (left * ratio) / zoom;
    if (
      selectedTool === "commentArea" &&
      mouseDownPoint.x &&
      mouseDownPoint.y &&
      mouseDown
    ) {
      var width = event.screenX - mouseDownPoint.screenX;
      var height = event.screenY - mouseDownPoint.screenY;
      var originalWidth = (width * ratio) / zoom;
      var originalHeight = (height * ratio) / zoom;
      if (height < 20) {
        height = 20;
      }
      if (width < 20) {
        width = 20;
      }
      setSelectionArea({
        top,
        left,
        width,
        height,
        originalLeft,
        originalTop,
        originalHeight,
        originalWidth,
      });
    } else if (selectedTool == "sticker" && selectedSticker) {
      if (event.target.id === "essay") {
        var width = 64;
        var height = 64;
        var originalWidth = width * ratio;
        var originalHeight = height * ratio;
        var left = event.nativeEvent.offsetX;
        var top = event.nativeEvent.offsetY;
        var originalTop = top * ratio;
        var originalLeft = left * ratio;
        setSelectionArea({
          top,
          left,
          width,
          height,
          originalLeft,
          originalTop,
          originalHeight,
          originalWidth,
        });
      }
    }
  }

  function handleSelectionAreaUpdate(selectionArea) {
    setSelectionArea(selectionArea);
  }

  function handleResize(style, isShiftKey, type, elementType) {
    let { top, left, width, height } = style;
    top = Math.round(top);
    left = Math.round(left);
    width = Math.round(width);
    height = Math.round(height);
    var zoom = essayZoom / 100;
    var originalHeight = (height * ratio) / zoom;
    var originalWidth = (width * ratio) / zoom;

    if (elementType === "sticker") {
      var biggestSize = height > width ? height : width;
      height = biggestSize;
      width = biggestSize;
    }

    var newElements = elements.map((el) => {
      if (el.id == selectedElement) {
        return {
          ...el,
          top,
          left,
          width,
          height,
          originalWidth,
          originalHeight,
        };
      } else {
        return el;
      }
    });
    setElements(newElements);
  }

  function handleRotate(rotateAngle) {
    setSelectionArea({
      rotateAngle,
    });
  }

  function handleDrag(deltaX, deltaY) {
    var newElements = elements.map((el) => {
      var top = el.top + deltaY;
      var left = el.left + deltaX;
      var zoom = essayZoom / 100;
      var originalTop = (top * ratio) / zoom;
      var originalLeft = (left * ratio) / zoom;
      if (el.id == selectedElement) {
        return {
          ...el,
          top,
          left,
          originalTop,
          originalLeft,
        };
      } else {
        return el;
      }
    });
    setElements(newElements);
  }

  function setComment(e, id) {
    setElements(
      elements.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            text: e.target.value,
            teacher: el.teacher ? el.teacher : user.name,
          };
        } else {
          return el;
        }
      })
    );
  }

  function setColor(colors, id) {
    setElements(
      elements.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            colors: colors,
          };
        } else {
          return el;
        }
      })
    );
  }

  function setDeleted(id) {
    setElements(
      elements.filter((el) => {
        if (el.id !== id) {
          return el;
        }
      })
    );
    VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
      var xhr4 = new XMLHttpRequest();
      xhr4.addEventListener("load", () => {});

      xhr4.open(
        "DELETE",
        baseURL +
          "o/" +
          query.get("oid") +
          "/groups/" +
          query.get("groupId") +
          "/texts/" +
          query.get("textId") +
          "/users/" +
          query.get("correctionId") +
          "/corrections/" +
          query.get("correctorId") +
          "/comments",
        true
      );
      xhr4.setRequestHeader("Content-Type", "application/json");
      xhr4.setRequestHeader("authorization", "Bearer " + tokenA);
      xhr4.send(JSON.stringify([id]));
    });
  }

  function getRectColor(el) {
    if (el.type === "commentArea") {
      if (el.id == selectedElement) {
        return el.colors.selectedColor;
      } else if (el.id == hoveredElement) {
        return el.colors.dotColor;
      } else {
        return el.colors.halfColor;
      }
    } else if (el.type === "sticker") {
      return null;
    }
  }

  function setVisibility() {
    setTimeout(() => {
      setShowingCards(!showingCards);
    }, 300);
    setTimeout(
      () => {
        setCardsVisible(!cardsVisible);
      },
      rightBarExpanded ? 1000 : 0
    );
    setRightBarExpanded(!rightBarExpanded);
  }

  const steps = isFinal
    ? ["Competências", "Nota", "Considerações", "Finalização"]
    : ["Competências", "Nota", "Finalização"];

  const handleNext = (isFinish) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    var body = {};
    VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
      assignments.forEach(function (a, index) {
        var xhr4 = new XMLHttpRequest();
        xhr4.addEventListener("load", () => {});

        xhr4.open(
          "POST",
          baseURL +
            "o/" +
            query.get("oid") +
            "/groups/" +
            query.get("groupId") +
            "/texts/" +
            query.get("textId") +
            "/users/" +
            query.get("correctionId") +
            "/corrections/" +
            query.get("correctorId") +
            "/assignments",
          true
        );
        xhr4.setRequestHeader("Content-Type", "application/json");
        xhr4.setRequestHeader("authorization", "Bearer " + tokenA);
        xhr4.send(
          JSON.stringify({
            id: `${index}`,
            comment: getCommentValue(index),
            grade: getSliderValue(index),
          })
        );
      });

      var xhr4 = new XMLHttpRequest();
      xhr4.addEventListener("load", () => {});

      xhr4.open(
        "POST",
        baseURL +
          "o/" +
          query.get("oid") +
          "/groups/" +
          query.get("groupId") +
          "/texts/" +
          query.get("textId") +
          "/users/" +
          query.get("correctionId") +
          "/corrections/" +
          query.get("correctorId") +
          "/assignments",
        true
      );
      xhr4.setRequestHeader("Content-Type", "application/json");
      xhr4.setRequestHeader("authorization", "Bearer " + tokenA);
      xhr4.send(
        JSON.stringify({
          id: "final",
          comment: finalComment,
        })
      );

      if (isFinish) {
        setShowingLoad(true);
        var xhr5 = new XMLHttpRequest();
        xhr5.addEventListener("load", () => {
          if (xhr5.status === 200) {
            setShowingLoad(false);
            //texts?oid=uAx9rdj&groupId=88DzRnv1F&textId=IfqwB6vJJY9kT39GQbZGEPbUm&groupName=Turma%20de%20testes&year=2023
            goTo(
              "texts?oid=" +
                query.get("oid") +
                "&groupId=" +
                query.get("groupId") +
                "&textId=" +
                query.get("textId") +
                "&groupName=" +
                query.get("groupName") +
                "&year=" +
                query.get("year")
            );
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
            setShowingLoad(false);
          }
        });

        xhr5.open(
          "POST",
          baseURL +
            "o/" +
            query.get("oid") +
            "/groups/" +
            query.get("groupId") +
            "/texts/" +
            query.get("textId") +
            "/users/" +
            query.get("correctionId") +
            "/corrections/" +
            query.get("correctorId") +
            "/correct",
          true
        );
        xhr5.setRequestHeader("Content-Type", "application/json");
        xhr5.setRequestHeader("authorization", "Bearer " + tokenA);
        xhr5.send();
      }
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function getSliderSet(index, value) {
    index = index + 1;
    switch (index) {
      case 1:
        setSlider1(value);
        break;
      case 2:
        setSlider2(value);
        break;
      case 3:
        setSlider3(value);
        break;
      case 4:
        setSlider4(value);
        break;
      case 5:
        setSlider5(value);
        break;
    }
  }

  function getSliderValue(index, value) {
    index = index + 1;
    switch (index) {
      case 1:
        return slider1;
      case 2:
        return slider2;
      case 3:
        return slider3;
      case 4:
        return slider4;
      case 5:
        return slider5;
    }
  }

  function getSliders() {
    return assignments.map(function (a, index) {
      return (
        <div>
          <Typography id="discrete-slider" gutterBottom>
            {a.name}
          </Typography>
          <Slider
            defaultValue={getSliderValue(index)}
            getAriaValueText={(value) => {
              return value ? value : "0";
            }}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={a.grade.breakpoint}
            marks
            min={a.grade.min}
            max={a.grade.max}
            onChangeCommitted={(ev, value) => getSliderSet(index, value)}
          />
        </div>
      );
    });
  }

  function getTabs() {
    var array = [];
    assignments.forEach(function (a, index) {
      array.push(<Tab label={a.name} {...a11yProps(index)} />);
    });
    return array;
  }

  function getCommentValue(index) {
    index = index + 1;
    switch (index) {
      case 1:
        return comment1;
      case 2:
        return comment2;
      case 3:
        return comment3;
      case 4:
        return comment4;
      case 5:
        return comment5;
    }
  }

  function getTabContent() {
    var array = [];

    var getOnChange = function (value, index) {
      index = index + 1;
      switch (index) {
        case 1:
          setComment1(value);
          break;
        case 2:
          setComment2(value);
          break;
        case 3:
          setComment3(value);
          break;
        case 4:
          setComment4(value);
          break;
        case 5:
          setComment5(value);
          break;
      }
    };

    assignments.forEach(function (a, index) {
      array.push(
        <div
          role="tabpanel"
          hidden={assignmentIndex !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{ marginTop: "15px" }}
        >
          {assignmentIndex === index && (
            <div>
              <p>{a.content}</p>
              <TextField
                id="standard-multiline-flexible"
                label={a.name}
                multiline
                rows={4}
                variant="outlined"
                value={getCommentValue(index)}
                onChange={(ev) => getOnChange(ev.target.value, index)}
                fullWidth={true}
              />
            </div>
          )}
        </div>
      );
    });
    return array;
  }

  function getFinalGrade() {
    if (gradeConfig) {
      var max = gradeConfig.max;
      var grade = slider1 + slider2 + slider3 + slider4 + slider5;

      return "Nota final: " + grade + "/" + max;
    }
  }

  function getStepContent(index) {
    if (assignments) {
      if (index == 0) {
        return (
          <div>
            <div style={{ flexGrow: 1 }}>
              <AppBar position="static">
                <Tabs
                  value={assignmentIndex}
                  onChange={(ev, newIndex) => setAssignmentIndex(newIndex)}
                  aria-label="simple tabs example"
                >
                  {getTabs()}
                </Tabs>
              </AppBar>
              {getTabContent()}
            </div>
          </div>
        );
      }
      if (index == 1) {
        return <div>{getSliders()}</div>;
      }
      if (index == 2 && isFinal) {
        return (
          <TextField
            id="standard-multiline-flexible"
            label={steps[index]}
            multiline
            rows={4}
            variant="outlined"
            value={finalComment}
            onChange={(ev) => setFinalComment(ev.target.value)}
            fullWidth={true}
          />
        );
      }
      if (index == 3 || (index == 2 && !isFinal)) {
        return (
          <div>
            <Typography>{getFinalGrade()}</Typography>
          </div>
        );
      }
    }
  }

  var commentTags = [];
  var commentArea = [];

  if (showElements) {
    elements.forEach((ca, index) => {
      if (ca.type === "commentArea") {
        commentArea.push(
          <ResizableRect
            left={ca.left}
            top={ca.top}
            width={ca.width}
            height={ca.height}
            zoomable={
              ca.id == selectedElement ? "n, w, s, e, nw, ne, se, sw" : ""
            }
            onResize={(style, isShiftKey, type) =>
              handleResize(style, isShiftKey, type, ca.type)
            }
            onDrag={handleDrag}
            auxStyle={{
              ...styles.commentArea,
              backgroundColor: ca.colors.backgroundColor,
              borderColor: ca.colors.borderColor,
            }}
            onClick={() => setSelectedElement(ca.id)}
            onHoverStart={() => setHoveredElement(ca.id)}
            onHoverEnd={() => setHoveredElement(null)}
            //text={ca.text}
            minWidth={20}
            minHeight={20}
            id={ca.id}
            sticker={ca.sticker}
          />
        );
      } else if (ca.type === "sticker") {
        commentArea.push(
          <ResizableRect
            left={ca.left}
            top={ca.top}
            width={ca.width}
            height={ca.height}
            zoomable={ca.id == selectedElement ? "nw, ne, se, sw" : ""}
            onResize={(style, isShiftKey, type) =>
              handleResize(style, isShiftKey, type, ca.type)
            }
            onDrag={handleDrag}
            auxStyle={{
              ...styles.commentArea,
              backgroundColor: "transparent",
              borderColor: ca.id == selectedElement ? null : "transparent",
            }}
            onClick={() => setSelectedElement(ca.id)}
            id={ca.id}
            sticker={ca.sticker}
          />
        );
      }
    });
  }

  if (showElements) {
    var virtualCommentArea =
      selectionArea.height && selectionArea.top ? (
        <ResizableRect
          left={selectionArea.left}
          top={selectionArea.top}
          width={selectionArea.width}
          height={selectionArea.height}
          rotateAngle={selectionArea.rotateAngle}
          onResize={handleResize}
          onDrag={handleDrag}
          auxStyle={{
            backgroundColor: defaultBackgroundColor,
            borderColor: defaultBorderColor,
            zIndex: 999,
          }}
          sticker={selectedSticker}
        />
      ) : (
        <div />
      );
  }

  var cards = [];
  elements.forEach((el, index) => {
    cards.push(
      <Card
        data={el}
        isSelected={selectedElement == el.id}
        onClick={() => setSelectedElement(el.id)}
        text={el.text}
        onChangeText={(e) => setComment(e, el.id)}
        onChangeColor={setColor}
        id={el.id}
        onDelete={() => setDeleted(el.id)}
        type={el.type}
        backgroundColor={
          el.colors && el.colors.backgroundColor
            ? el.colors.backgroundColor
            : defaultBackgroundColor
        }
        borderColor={
          el.colors && el.colors.borderColor
            ? el.colors.borderColor
            : defaultBorderColor
        }
        showingCards={showingCards}
      />
    );
  });

  return (
    <div>
      <Backdrop open={showingLoad} style={{ color: "#fff", zIndex: "999" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToolBar
        selectedTool={selectedTool}
        setSelectedTool={setSelectedTool}
        setSelectedSticker={setSelectedSticker}
        showElements={showElements}
        setShowElements={setShowElements}
        setZoom={setEssayZoom}
        zoom={essayZoom}
        setDefaultBackgroundColor={setDefaultBackgroundColor}
        setDefaultBorderColor={setDefaultBorderColor}
        setQuickComment={setQuickComment}
      ></ToolBar>
      <div className="EssayCorrector" style={{ display: "flex" }}>
        <div style={styles.imageBase}>
          <div
            style={styles.imageContainer}
            id="essayContainer"
            onClick={handleClick}
            onMouseDown={handleMouseDown}
            onTouchStart={handleMouseDown}
            onMouseUp={handleMouseUp}
            onTouchMove={handleMove}
            onMouseMove={handleMove}
            onDragStart={handleDragStart}
          >
            <img
              id="essay"
              src={textURL}
              draggable={false}
              alt="essay"
              style={{ width: essayZoom + "%", userSelect: "none" }}
            />
            {virtualCommentArea}

            {commentArea}

            {commentTags}
          </div>
        </div>

        <div
          style={{
            ...styles.rightBar,
            width: rightBarExpanded ? "500px" : "55px",
          }}
        >
          <div
            style={{
              height: "55px",
              width: "100%",
              backgroundColor: "#5f5d5d",
            }}
          >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={setVisibility}
            >
              {rightBarExpanded ? (
                <KeyboardArrowRightIcon
                  style={{ fontSize: 30, color: "rgb(224,224,224)" }}
                />
              ) : (
                <KeyboardArrowLeftIcon
                  style={{ fontSize: 30, color: "rgb(224,224,224)" }}
                />
              )}
            </IconButton>
          </div>
          <div style={{ marginBottom: "20px" }}>
            {cardsVisible ? cards : <div />}
          </div>
        </div>
      </div>
      {transcription && (
        <div
          style={{ maxWidth: "60vw", marginLeft: "auto", marginRight: "auto" }}
        >
          <h3>Transcrição da redação - Total {transcriptionLenght} letras</h3>
          <div
            style={{ textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: transcription }}
          ></div>
        </div>
      )}

      <div
        style={{ maxWidth: "75vw", marginLeft: "auto", marginRight: "auto" }}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Voltar
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() =>
                        handleNext(activeStep === steps.length - 1)
                      }
                      className={classes.button}
                    >
                      {activeStep === steps.length - 1
                        ? "Finalizar"
                        : "Próximo"}
                    </Button>
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
}

const styles = {
  imageBase: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgb(153,51,204)",
  },

  rightBar: {
    height: "820px",
    justifyContent: "center",
    backgroundColor: "rgb(36,35,37)",
    overflow: "auto",
    transition: "width 1s",
  },
  imageContainer: {
    width: "70vw",
    height: "83vh",
    overflow: "scroll",
    position: "relative",
    marginTop: "20px",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  commentArea: {
    cursor: "pointer",
    zIndex: 555,
    borderWidth: "2px",
  },
};

export default EssayCorrector;
