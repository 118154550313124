import React, { useState, useEffect } from 'react';
import 'fontsource-roboto';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

function Settings(props) {
	const [expanded, setExpanded] = React.useState(false);

  useEffect(() => {

  }, []);

	const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
	};
	
	const GrafiameSwitch = withStyles({
    switchBase: {
      color: "#545454",
      '&$checked': {
        color: "#85458f",
      },
      '&$checked + $track': {
        backgroundColor: "#85458f",
      },
    },
    checked: {},
    track: {},
  })(Switch);
 
  return (
      <div>
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>

              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className={styles.heading}>Configurações</Typography>
              </AccordionSummary>
              <div style={{display: 'flex', marginLeft: '40px', marginBottom: '10px'}}>
								<div style={styles.EyeIcon} >
										{
											<FormControlLabel
												control={
													<GrafiameSwitch
														checked={props.showElements}
														onChange={(event) => { props.setShowElements(event.target.checked); props.setSelectedTool(event.target.checked ? 'commentArea' : '')}}
														name="checkedB"
													/>
												}
												label={props.showElements ? 'Mostrar correção' : 'Esconder correção'}
											/>
										}
								</div>
              </div>
          </Accordion>
      </div>
  
  );
}

const styles = {
 
};

export default Settings;
