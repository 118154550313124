import React, { useState, useEffect } from "react";
import "fontsource-roboto";
import Skeleton from "@material-ui/lab/Skeleton";
import Typography from "@material-ui/core/Typography";
import { DataGrid } from "@material-ui/data-grid";
import firebase from "firebase/app";
import { VerifyAuth, goTo, getDate, baseURL } from "./webHandler";
import { useSessionStorage } from "react-use-storage";
import Button from "@material-ui/core/Button";

import "firebase/storage";
import "firebase/analytics";
import Table from "@material-ui/core/Table";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TableBody from "@material-ui/core/TableBody";
import Modal from "@material-ui/core/Modal";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TextField from "@material-ui/core/TextField";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

require("firebase/firestore");

const columns = [
  { field: "name", headerName: "Aluno", width: 300 },
  { field: "sendTime", headerName: "Data de envio", width: 300 },
  { field: "statusName", headerName: "Status da redação", width: 300 },
  { field: "grade", headerName: "Nota", width: 300 },
  { field: "actions", headerName: "Ações", width: 300, center: true },
];

function Texts() {
  const [texts, setTexts] = useState([]);
  const [requestDone, setRequestDone] = useState(false);
  const [bearer, setBearer] = useSessionStorage("bearer");
  const [textURL, setTextURL] = useSessionStorage("textURL", "");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  const [addingNewEssay, setAddingNewEssay] = useState(false);
  const [showingLoad, setShowingLoad] = useState(false);
  const [idUser, setIdUser] = useState("");
  const [transcription, setTranscription] = useState("");
  const [uidUser, setUidUser] = useState("");

  const sendEssay = (e) => {
    const file = e.target.files[0];

    if (query.get("oid") != "uAx9rdj") {
      const storageRef = firebase
        .storage()
        .refFromURL(
          "gs://grafiame-grafiame/years/2024/groups/" +
            query.get("groupId") +
            "/texts/" +
            query.get("textId") +
            "/users/" +
            idUser +
            "/"
        );
      const fileRef = storageRef.child("Essay");
      fileRef.put(file).then(() => {
        setShowingLoad(true);
        // console.log("File uploaded");
        VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
          //console.log(tokenA)
          var xhr11 = new XMLHttpRequest();
          xhr11.addEventListener("load", () => {
            setShowingLoad(false);
            setRequestDone(true);
            setAddingNewEssay(false);
            // console.log('atualizado com sucesso');
          });
          xhr11.open(
            "POST",
            baseURL +
              "o/" +
              query.get("oid") +
              "/groups/" +
              query.get("groupId") +
              "/texts/" +
              query.get("textId") +
              "/users/" +
              idUser +
              "/",
            true
          );
          xhr11.setRequestHeader("authorization", "Bearer " + tokenA);
          xhr11.setRequestHeader(
            "Content-Type",
            "application/json;charset=UTF-8"
          );
          xhr11.send(
            JSON.stringify({
              transcription: transcription,
            })
          );
        });
      });
    }
    if (query.get("oid") == "uAx9rdj") {
      const storageRef = firebase
        .storage()
        .refFromURL(
          "gs://integradaeducativa-grafiame/years/2024/groups/" +
            query.get("groupId") +
            "/texts/" +
            query.get("textId") +
            "/users/" +
            idUser +
            "/"
        );
      const fileRef = storageRef.child("Essay");
      fileRef.put(file).then(() => {
        setShowingLoad(true);
        // console.log("File uploaded");
        VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
          //console.log(tokenA)
          var xhr11 = new XMLHttpRequest();
          xhr11.addEventListener("load", () => {
            setShowingLoad(false);
            setRequestDone(true);
            setAddingNewEssay(false);
            // console.log('atualizado com sucesso');
          });
          xhr11.open(
            "POST",
            baseURL +
              "o/" +
              query.get("oid") +
              "/groups/" +
              query.get("groupId") +
              "/texts/" +
              query.get("textId") +
              "/users/" +
              idUser +
              "/",
            true
          );
          xhr11.setRequestHeader("authorization", "Bearer " + tokenA);
          xhr11.setRequestHeader(
            "Content-Type",
            "application/json;charset=UTF-8"
          );
          xhr11.send(
            JSON.stringify({
              transcription: transcription,
            })
          );
        });
      });
    }
  };
  useEffect(() => {
    setRequestDone(false);
    VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
      setUidUser(currentUser.uid);
      if (!db) {
        var db = firebase.firestore();
      }
      if (query.get("year")) {
        db.collection(
          "/organizations/" +
            query.get("oid") +
            "/years/" +
            query.get("year") +
            "/groups/" +
            query.get("groupId") +
            "/texts/" +
            query.get("textId") +
            "/users"
        )
          .orderBy("name")
          .onSnapshot(
            {
              // Listen for document metadata changes
              includeMetadataChanges: true,
            },
            function (snapshot) {
              var result = [];
              if (snapshot.docs && snapshot.docs.length > 0) {
                snapshot.docs.forEach(function (doc) {
                  var text = doc.data();
                  text.textId = doc.id;
                  if (text.creatBy) {
                    var exists = texts.filter(function (t) {
                      return t.creatBy == text.creatBy;
                    });
                    if (
                      currentUser.uid == "g-AmAeHRTKt4A5qvUqT2xiJ4" ||
                      currentUser.uid == "Ry0xn02sg8SV3KJgf7qVdgpIv7H3"
                    ) {
                      if (!exists || exists.length == 0) {
                        text.id = texts.length + 1;
                        result.push(text);
                      } else {
                        setTexts(
                          setTexts.map(function (t) {
                            if (t.creatBy == exists[0].creatBy) {
                              return text;
                            } else {
                              return t;
                            }
                          })
                        );
                      }
                    } else {
                      if (!exists || exists.length == 0) {
                        if (text.creatBy == currentUser.uid) {
                          text.id = texts.length + 1;
                          result.push(text);
                        }
                      }
                    }
                  }
                });
                setTexts(result);
              } else {
                alert("Nenhuma redação enviada.");
              }
            }
          );
      } else {
        db.collection(
          "/organizations/" +
            query.get("oid") +
            "/years/2022/groups/" +
            query.get("groupId") +
            "/texts/" +
            query.get("textId") +
            "/users"
        )
          .orderBy("name")
          .onSnapshot(
            {
              // Listen for document metadata changes
              includeMetadataChanges: true,
            },
            function (snapshot) {
              var result = [];
              if (snapshot.docs && snapshot.docs.length > 0) {
                snapshot.docs.forEach(function (doc) {
                  var text = doc.data();
                  text.textId = doc.id;
                  if (text.creatBy) {
                    var exists = texts.filter(function (t) {
                      return t.creatBy == text.creatBy;
                    });
                    if (
                      currentUser.uid == "g-AmAeHRTKt4A5qvUqT2xiJ4" ||
                      currentUser.uid == "Ry0xn02sg8SV3KJgf7qVdgpIv7H3"
                    ) {
                      if (!exists || exists.length == 0) {
                        text.id = texts.length + 1;
                        result.push(text);
                      } else {
                        setTexts(
                          setTexts.map(function (t) {
                            if (t.creatBy == exists[0].creatBy) {
                              return text;
                            } else {
                              return t;
                            }
                          })
                        );
                      }
                    } else {
                      if (!exists || exists.length == 0) {
                        if (text.creatBy == currentUser.uid) {
                          text.id = texts.length + 1;
                          result.push(text);
                        }
                      }
                    }
                  }
                });
                setTexts(result);
              } else {
                alert("Nenhuma redação enviada.");
              }
            }
          );
      }
    });
  }, []);

  const getStatus = function (status) {
    if (status == "n") {
      return "Não enviada";
    }
    if (status == "e") {
      return "Enviada";
    }
    if (status == "m") {
      return "Em correção";
    }
    if (status == "c") {
      return "Corrigida";
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  }));

  const classes = useStyles();
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  function EnhancedTableHead(props) {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? "right" : "left"}
              padding={headCell.disablePadding ? "none" : "default"}
              sortDirection={orderBy === headCell.id ? order : false}
              style={{
                backgroundColor: "#bbbbbb",
                textAlign: headCell.center ? "center" : "",
              }}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                style={{ fontWeight: 600 }}
              >
                {headCell.headerName}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };

  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  }));

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();

    return (
      <Toolbar className={clsx(classes.root)}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
          style={{ paddingTop: "10px" }}
        >
          Redações enviadas
        </Typography>
      </Toolbar>
    );
  };

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function viewText(row) {
    VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
      if (row.category === "Simulado") {
        goTo(
          "essayViewer?oid=" +
            query.get("oid") +
            "&groupId=" +
            query.get("groupId") +
            "&textId=" +
            query.get("textId") +
            "&correctionId=" +
            row.textId +
            "&correctorId=" +
            currentUser.uid +
            "&userName=" +
            row.name +
            "&groupName=" +
            query.get("groupName") +
            "&year=" +
            query.get("year")
        );
      } else {
        goTo(
          "essayViewer?oid=" +
            query.get("oid") +
            "&groupId=" +
            query.get("groupId") +
            "&textId=" +
            query.get("textId") +
            "&correctionId=" +
            row.textId +
            "&correctorId=" +
            row.correctorID +
            "&userName=" +
            row.name +
            "&groupName=" +
            query.get("groupName") +
            "&year=" +
            query.get("year")
        );
      }
    });
  }

  function editText(row) {
    VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
      if (row.category === "Simulado") {
        goTo(
          "essayCorrector?oid=" +
            query.get("oid") +
            "&groupId=" +
            query.get("groupId") +
            "&textId=" +
            query.get("textId") +
            "&correctionId=" +
            row.textId +
            "&correctorId=" +
            currentUser.uid +
            "&userName=" +
            row.name +
            "&groupName=" +
            query.get("groupName") +
            "&year=" +
            query.get("year")
        );
      } else {
        const correctorID = row.correctorID ? row.correctorID : currentUser.uid;
        goTo(
          "essayCorrector?oid=" +
            query.get("oid") +
            "&groupId=" +
            query.get("groupId") +
            "&textId=" +
            query.get("textId") +
            "&correctionId=" +
            row.textId +
            "&correctorId=" +
            correctorID +
            "&userName=" +
            row.name +
            "&groupName=" +
            query.get("groupName") +
            "&year=" +
            query.get("year")
        );
      }
    });
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, texts.length - page * rowsPerPage);

  const stableSort = function (array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  return (
    <div style={styles.outterDiv}>
      <div style={styles.topBar}>
        <Typography
          variant="h3"
          style={{ paddingTop: "8px", paddingLeft: "20px" }}
        >
          Lista de redações - {query.get("groupName")}
        </Typography>
      </div>
      <div style={styles.innerContent}>
        <div style={{ position: "fixed", bottom: "20px", left: "20px" }}>
          {uidUser != "g-AmAeHRTKt4A5qvUqT2xiJ4" &&
          uidUser != "Ry0xn02sg8SV3KJgf7qVdgpIv7H3" ? (
            <div />
          ) : (
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={() => setAddingNewEssay(true)}
            >
              <AddCircleIcon
                style={styles.zoomIcon}
                style={{ fontSize: "50px" }}
              />
            </IconButton>
          )}
        </div>
        <Modal
          open={addingNewEssay}
          onClose={() => setAddingNewEssay(false)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              width: "600px",
              height: "623px",
              backgroundColor: "white",
              margin: "auto",
              marginTop: "10vh",
            }}
          >
            <div id="scroll" style={{ overflow: "auto" }}>
              <Backdrop
                open={showingLoad}
                style={{ color: "#fff", zIndex: "999" }}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
              <div style={{ maxHeight: "600px" }}>
                <div style={{ margin: "30px" }}>
                  <TextField
                    style={{ width: "150", marginBottom: "20px" }}
                    id="outlined-basic"
                    label="Id user"
                    variant="outlined"
                    value={idUser}
                    onChange={(ev) => {
                      setIdUser(ev.target.value);
                    }}
                    error={idUser == "" && idUser.length == 0}
                    id="outlined-error"
                    label="Id user"
                    variant="outlined"
                  />
                  <TextField
                    style={{ width: "150", marginBottom: "20px" }}
                    id="outlined-basic"
                    label="Transcription"
                    variant="outlined"
                    value={transcription}
                    onChange={(ev) => {
                      setTranscription(ev.target.value);
                    }}
                    error={transcription == "" && transcription.length == 0}
                    id="outlined-error"
                    label="Transcription"
                    variant="outlined"
                  />
                  <br></br>
                  <input type="file" onChange={sendEssay}></input>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <div style={{ height: "80vh", width: "100%" }}>
          <div className={classes.root}>
            <Paper className={classes.paper}>
              <EnhancedTableToolbar />
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={"medium"}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                    rowCount={texts.length}
                  />
                  <TableBody>
                    {stableSort(texts, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.name}
                          >
                            <TableCell
                              component="th"
                              id={labelId}
                              scope="row"
                              padding="default"
                            >
                              {row.name}
                            </TableCell>

                            <TableCell align="left">
                              {getDate(row.time)}
                            </TableCell>

                            <TableCell align="left" style={{ fontWeight: 600 }}>
                              {getStatus(row.status)}
                            </TableCell>
                            <TableCell align="left" style={{ fontWeight: 600 }}>
                              {row.grade}
                            </TableCell>

                            <TableCell align="center">
                              <IconButton
                                aria-label="filter list"
                                disabled={
                                  row.creatBy != uidUser &&
                                  uidUser != "g-AmAeHRTKt4A5qvUqT2xiJ4" &&
                                  uidUser != "Ry0xn02sg8SV3KJgf7qVdgpIv7H3"
                                }
                                onClick={() => viewText(row)}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                              {uidUser != "g-AmAeHRTKt4A5qvUqT2xiJ4" &&
                              uidUser != "Ry0xn02sg8SV3KJgf7qVdgpIv7H3" ? (
                                <div />
                              ) : (
                                <IconButton
                                  aria-label="filter list"
                                  disabled={
                                    uidUser != "g-AmAeHRTKt4A5qvUqT2xiJ4" &&
                                    uidUser != "Ry0xn02sg8SV3KJgf7qVdgpIv7H3"
                                  }
                                  onClick={() => editText(row)}
                                >
                                  <EditIcon fontSize="small" />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 110 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={texts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                labelRowsPerPage={"Redações por página"}
              />
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  outterDiv: {
    width: "100%",
    height: "100%",
  },
  topBar: {
    height: "80px",
    backgroundColor: "rgb(109,109,109)",
  },
  innerContent: {
    height: "100%",
    marginLeft: "70px",
    marginTop: "50px",
    marginRight: "70px",
    marginBottom: "50px",
    flexFlow: "wrap",
    display: "flex",
    justifyContent: "center",
  },
  card: {
    margin: "30px",
    width: "720px",
    height: "260px",
    cursor: "pointer",
  },
  cardUpperBar: {
    width: "100%",
    height: "80px",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    display: "flex",
  },
  textBox: {
    maxWidth: "330px",
    maxHeight: "80px",
    marginLeft: "20px",
    overflow: "hidden",
    display: "Webkit-box",
    WebkitLineClamp: 3,
    marginLeft: "20px",
    marginTop: "10px",
  },
};

export default Texts;
