import React, { useState, useEffect } from 'react';
import 'fontsource-roboto';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmailIcon from '@material-ui/icons/Email';
import firebase from "firebase/app";
import Snackbar from '@material-ui/core/Snackbar';
import "firebase/auth";
import {
	FirebaseAuthProvider,
	IfFirebaseUnAuthed,
	IfFirebaseAuthed,
	FirebaseAuthConsumer,
	IfFirebaseAuthedAnd
  } from "react-firebase-auth-provider";
  import {verifyAuth, credentialsSetter, goTo, baseURL} from './webHandler';
  import {useSessionStorage} from "react-use-storage";

function Login() {
	const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
	});
	const [email, setEmail] = useState('');
	const [forgotPassword, setforgotPassword] = useState(false);
	const [showingCard, setshowingCard] = useState(1);
	const [uppingCard, setUppingCard] = useState(false);
	const [showingLoad, setShowingLoad] = useState(false);
	const [credentials, setCredentials, removeCredentials] = useSessionStorage("credentials", {email: '', password: ''});
	const [bearer, setBearer] = useSessionStorage("bearer", '');
	const [currentUser, setCurrentUser] = useSessionStorage("currentUser", '');
	const [oid, setOid] = useSessionStorage("oid");
	const [groupId, setGroupId] = useSessionStorage("groupId");
	const [textId, setTextId] = useSessionStorage("textId");
	const [showSnack, setShowSnack] = useState(false);

	const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
	};
	
	const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
	};

	const goBack = function () {
		setShowingLoad(false);
		setUppingCard(false);
		setshowingCard(1);
		setforgotPassword(false);
	}
	
	const changeCards = function () {
		setforgotPassword(true);
		setTimeout(function () {
			setshowingCard(2);
			setTimeout(function () {
				setUppingCard(true);
			}, 100);
		},900)
	}

	const setLoading = function () {
		setShowingLoad(true);
		setTimeout(function() {
			setShowingLoad(false);
			setshowingCard(3);
		},500);
	}

  useEffect(() => {
	setBearer('');
	setCurrentUser('');
	setOid('');
	setGroupId('');
	setTextId('');
	firebase.auth().signOut();
  }, []);

  const tryLogin = function () {
	setShowingLoad(true);
	firebase.auth().onAuthStateChanged(function(currentUser) {
		if (currentUser) {
			firebase.auth().currentUser.getIdToken(true)
			.then(function(tokenA) {
				var xhr = new XMLHttpRequest();
				xhr.addEventListener('load', () => {
					if (xhr.responseText != 'Unauthorized'){
						var tokenP = (JSON.parse(xhr.responseText) ||{}).tokenP;
						setBearer(tokenP);
						firebase.auth().signInWithCustomToken(tokenP)
							.then((currentUser) => {
								setShowingLoad(false);
								setCurrentUser(currentUser);
								goTo('organizations');
							})
							.catch((error) => {
								setShowingLoad(false);
								var errorCode = error.code;
								var errorMessage = error.message;
								setShowSnack(true);
							});
					} else {
						setShowingLoad(false);
						//var errorCode = error.code;
						//var errorMessage = error.message;
						setShowSnack(true);
					}
				})
				xhr.open("GET", baseURL+'auth', true);
				xhr.setRequestHeader("authorization", 'Bearer ' + tokenA);
				xhr.send();
			}).catch(function(error) {
				setShowingLoad(false);
				var errorCode = error.code;
				var errorMessage = error.message;
				setShowSnack(true);
			});
		} else {
			firebase.auth().signInWithEmailAndPassword(email, values.password)
			.then((userCredential) => {
			  // Signed in

			  var user = userCredential.user;
			  tryLogin();
			})
			.catch((error) => {
				setShowingLoad(false);
				var errorCode = error.code;
				var errorMessage = error.message;
				setShowSnack(true);
			});
		}
	  });
	  
  }

  return (
    <div style={styles.outterDiv}>
	  <Backdrop open={showingLoad} style={{color: '#fff', zIndex: '999'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

	  	<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={showSnack}
			autoHideDuration={3000}
			onClose={() => setShowSnack(false)}
			message="Usuário ou senha incorretos =("
			key={'topright'}
		/>	

	  {showingCard == 1 ? 
      <Paper 
        elevation={5} 
        style={{...styles.paper, marginTop: forgotPassword ? '-1500px' : '0px'}}
      >
        <div style={styles.avatarSection}>

          <div style={{width: '300px', height: '100px', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center'}}>
            <Avatar style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '20px', marginBottom: '20px'}} src="/broken-image.jpg" />

            <span style={{fontFamily: 'Roboto', fontSize: '20px'}}>Entrar na plataforma</span>
          </div>
          
        </div>

        <div style={styles.fieldsSection}>

			<TextField 
				id="outlined-basic" 
				label="Email" 
				variant="outlined" 
				style={{marginBottom: '20px', width: '350px'}}
				value={email}
				onChange={(value) => setEmail(value.target.value)}
			/>
	
			<FormControl variant="outlined">
				<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
				<OutlinedInput
					id="outlined-adornment-password"
					type={values.showPassword ? 'text' : 'password'}
					value={values.password}
					onChange={handleChange('password')}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{values.showPassword ? <Visibility /> : <VisibilityOff />}
							</IconButton>
						</InputAdornment>
					}
					labelWidth={70}
				/>
			</FormControl>
			<Link href="#" style={{marginTop: '10px'}} onClick={() => changeCards()}>
				Esqueceu a senha?
			</Link>
        </div>

			<div style={styles.buttonSection}>
				<Button variant="contained" style={styles.enterButton} onClick={tryLogin}>
					Entrar
				</Button>
			</div>
				
      </Paper> : <div/>}

		  { showingCard == 2 || showingCard == 3 ? 
			<Paper 
        elevation={5} 
        style={{...styles.paper, marginTop: uppingCard ? '0px' : '-1500px'}}
      > 
				<div style={{...styles.keySection, height: showingCard == 2 ? '55%' : '100%'}}>

					<div style={{width: '300px', height: '100px', marginLeft: 'auto', marginRight: 'auto', textAlign: 'center', display: 'contents'}}>
						{
							showingCard == 2 ?
							<VpnKeyIcon style={{fontSize: '70px', justifySelf: 'center', marginBottom: '10px'}}/>
							:
							<EmailIcon style={{fontSize: '70px', justifySelf: 'center', marginBottom: '10px'}}/>
						}
						{
							showingCard == 2 ?
							<span style={{fontFamily: 'Roboto', fontSize: '25px', marginBottom: '15px'}}>
								Esqueci a senha =(
							</span>
							:
							<span style={{fontFamily: 'Roboto', fontSize: '25px', marginBottom: '15px'}}>
								E-mail enviado!
							</span>	
						}
						{
							showingCard == 2 ?
							<span style={{fontFamily: 'Roboto', fontSize: '18px', margin: '30px'}}>
								Relaxa! Acontece com todo mundo. Enviaremos para seu endereço de e-mail um link que permite recuperar a sua conta.
							</span>
							:
							<span style={{fontFamily: 'Roboto', fontSize: '18px', margin: '30px'}}>
							Acabamos de enviar um e-mail para {email}. Caso não ache, dá uma olhada na caixa de spam, ou entre em contato com o responsável de tecnologia da sua organização 
							</span>
						}
						{
							showingCard == 3 ?
							<Button variant="contained" style={{...styles.sendButton, justifySelf: 'center', width: '180px'}} onClick={() => goBack()}>
								Voltar ao login
							</Button>
							: <div/>
						}
					</div>

				</div>

				{
					showingCard == 2 ?
					<div style={{...styles.keySection, height: '45%'}}>
						<TextField 
								id="outlined-basic" 
								label="Email" 
								variant="outlined" 
								style={{marginBottom: '20px', width: '350px'}}
								value={email}
								onChange={(value) => setEmail(value.target.value)}
						/>
						<div style={{ width: '100%',display: 'flex',flexDirection: 'row-reverse', paddingBottom: '40px'}}>
							<Button variant="contained" style={styles.sendButton} onClick={() => setLoading(true)}>
								Enviar
							</Button>
						</div>
						
					</div>
					: <div/>
				}

			</Paper>
			: <div/> }
    </div>
  );
}

const styles = {
  outterDiv: {
    height: '100vh', 
    width: '100vw', 
    backgroundColor: '#06d6a0', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center'
  },
  avatarSection: {
    height: '35%', 
    width: '100%',
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center'
	},
	keySection: {
    height: '55%', 
    width: '100%',
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center'
  },
  fieldsSection: {
    height: '40%', 
    width: '100%',
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center'
	},
	buttonSection: {
    height: '25%', 
    width: '100%',
		display: 'flex',
		flexDirection: 'row-reverse',
		
	},
	enterButton: {
		width: '100px', 
		height: '40px', 
		marginRight: '50px', 
		marginTop: '40px',
		color: 'white',
		backgroundColor: '#85458f'
	},
	sendButton: {
		width: '100px', 
		height: '40px', 
		marginTop: '40px',
		color: 'white',
		backgroundColor: '#85458f'
	},
	paper: {
		height: '80vh', 
		width: '25vw', 
		minWidth: '400px', 
		maxHeight: '550px', 
		transition: 'margin-top 0.8s',
	}

};

export default Login;
