import redação from "./redação.png";
import "./App.css";
import React, { useState, useEffect } from "react";
import ToolBar from "./ToolBar";
import Settings from "./Settings";
import Card from "./CommentCard";
import ResizableRect from "./react-resizable-rotatable-draggable/src/";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { useSessionStorage } from "react-use-storage";
import { VerifyAuth, goTo, getDate, baseURL } from "./webHandler";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Slider from "@material-ui/core/Slider";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import HelpIcon from "@material-ui/icons/Help";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { ControlCameraOutlined, Rotate90DegreesCcw } from "@material-ui/icons";

function EssayViewer() {
  // console.log(window.location.pathname);
  const [mouseDownPoint, setMouseDownPoint] = useState([]);
  const [hasMoved, setMoved] = useState(false);
  const [essayZoom, setEssayZoom] = useState(100);
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    essayWidth: window.innerWidth * (window.screen.availWidth < 900 ? 1 : 0.7),
  });
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const [showingLoad, setShowingLoad] = useState(true);
  const [showElements, setShowElements] = useState(true);
  const [ratio, setRatio] = useState(0);
  const [selectedTool, setSelectedTool] = useState("commentArea");
  const [mouseDown, setMouseDown] = useState(false);
  const [popover, setPopover] = useState(false);
  const [gradeConfig, setGradeConfig] = useState();

  const [bearer, setBearer] = useSessionStorage("bearer");

  const [elements, setElements] = useState([]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const [selectionArea, setSelectionArea] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  });

  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedSticker, setSelectedSticker] = useState(null);
  const [hoveredElement, setHoveredElement] = useState(null);

  const [defaultBackgroundColor, setDefaultBackgroundColor] =
    useState("rgba(255,0,0,0.5)");
  const [defaultBorderColor, setDefaultBorderColor] =
    useState("rgba(255,0,0,1)");

  const [rightBarExpanded, setRightBarExpanded] = useState(false);
  const [showingCards, setShowingCards] = useState(false);
  const [cardsVisible, setCardsVisible] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [comment1, setComment1] = useState("");
  const [comment2, setComment2] = useState("");
  const [comment3, setComment3] = useState("");
  const [comment4, setComment4] = useState("");
  const [comment5, setComment5] = useState("");
  const [finalComment, setFinalComment] = useState("");
  const [isFinal, setFinal] = useState(false);

  const [slider1, setSlider1] = useState(0);
  const [slider2, setSlider2] = useState(0);
  const [slider3, setSlider3] = useState(0);
  const [slider4, setSlider4] = useState(0);
  const [slider5, setSlider5] = useState(0);

  const [assignments, setAssignments] = useState([]);
  const [assignmentIndex, setAssignmentIndex] = useState(0);

  const [isMobile, setIsMobile] = useState(window.screen.availWidth < 900);

  const [textURL, setTextURL] = useState("");

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    // Update screen dimensions on resize
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
        essayWidth: window.innerWidth * (isMobile ? 1 : 0.7),
      });
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  useEffect(() => {
    // update elements on ratio change
    var auxRatio =
      imageDimensions.width / (dimensions.essayWidth + (isMobile ? +10 : 0));
    var zoom = essayZoom / 100;
    setRatio(auxRatio);
    setElements(
      elements.map((element) => {
        return {
          ...element,
          left: Math.round((element.originalLeft * zoom) / auxRatio),
          top: Math.round((element.originalTop * zoom) / auxRatio),
          height: Math.round((element.originalHeight * zoom) / auxRatio),
          width: Math.round((element.originalWidth * zoom) / auxRatio),
        };
      })
    );
  }, [dimensions, essayZoom, imageDimensions]);

  useEffect(() => {
    if (query.get("tka") != null) {
      var idcorretor;
      var xhr = new XMLHttpRequest();
      xhr.addEventListener("load", () => {
        setTextURL(JSON.parse(xhr.responseText).photoURL);
        setTimeout(function () {
          setImageDimensions({
            width: document.getElementById("essay").naturalWidth,
            height: document.getElementById("essay").naturalHeight,
          });
          setShowingLoad(false);
        }, 2000);
      });
      if (query.get("year") != null) {
        xhr.open(
          "GET",
          baseURL +
            "o/" +
            query.get("oid") +
            "/groups/" +
            query.get("groupId") +
            "/texts/" +
            query.get("textId") +
            "/users/" +
            query.get("correctionId") +
            "/corrections/photourl/" +
            query.get("year"),
          true
        );
      } else {
        xhr.open(
          "GET",
          baseURL +
            "o/" +
            query.get("oid") +
            "/groups/" +
            query.get("groupId") +
            "/texts/" +
            query.get("textId") +
            "/users/" +
            query.get("correctionId") +
            "/corrections/photourl/" +
            new Date().getFullYear(),
          true
        );
      }
      // xhr.open("GET", baseURL+'o/'+query.get("oid")+'/groups/'+query.get("groupId")+'/texts/'+query.get("textId")+'/users/'+query.get("correctionId")+'/corrections/text', true);
      if (query.get("tka") != null) {
        xhr.setRequestHeader("authorization", "Bearer " + query.get("tka"));
      }
      xhr.send();

      //

      var xhr2 = new XMLHttpRequest();
      xhr2.onreadystatechange = function () {
        if (xhr2.readyState === 4) {
          var x = JSON.parse(xhr2.responseText).criteria;
          var final = JSON.parse(xhr2.responseText).final;
          var config = JSON.parse(xhr2.responseText).config;

          if (config) {
            setGradeConfig(config.grade);
          }

          if (final) {
            setFinalComment(final.comment);
            setFinal(true);
          }

          var array = [{}, {}, {}, {}, {}];
          array = array.map(function (a, index) {
            if (x[index]) {
              return x[index];
            } else {
              return null;
            }
          });

          array = array.filter(function (a) {
            return a;
          });

          array.forEach(function (a, index) {
            var i = index + 1;
            switch (i) {
              case 1:
                setSlider1(a.grade ? a.grade.grade : 0);
                setComment1(a.comment);
                break;
              case 2:
                setSlider2(a.grade ? a.grade.grade : 0);
                setComment2(a.comment);
                break;
              case 3:
                setSlider3(a.grade ? a.grade.grade : 0);
                setComment3(a.comment);
                break;
              case 4:
                setSlider4(a.grade ? a.grade.grade : 0);
                setComment4(a.comment);
                break;
              case 5:
                setSlider5(a.grade ? a.grade.grade : 0);
                setComment5(a.comment);
                break;
            }
          });

          setAssignments(array);
        }
      };
      {
        query.get("year")
          ? xhr2.open(
              "GET",
              baseURL +
                "o/" +
                query.get("oid") +
                "/groups/" +
                query.get("groupId") +
                "/texts/" +
                query.get("textId") +
                "/users/" +
                query.get("correctionId") +
                "/corrections/" +
                query.get("correctorId") +
                "/assignments/" +
                query.get("year"),
              true
            )
          : xhr2.open(
              "GET",
              baseURL +
                "o/" +
                query.get("oid") +
                "/groups/" +
                query.get("groupId") +
                "/texts/" +
                query.get("textId") +
                "/users/" +
                query.get("correctionId") +
                "/corrections/" +
                query.get("correctorId") +
                "/assignments",
              true
            );
      }
      if (query.get("tka") != null) {
        xhr2.setRequestHeader("authorization", "Bearer " + query.get("tka"));
      }
      xhr2.send();

      //

      var xhr3 = new XMLHttpRequest();
      xhr3.onreadystatechange = function () {
        if (xhr3.readyState === 4) {
          var zoom = essayZoom / 100;
          var auxRatio = imageDimensions.width / dimensions.essayWidth;
          var x = JSON.parse(xhr3.response) || [];
          x = x.map(function (c) {
            c.top = (c.positions.top * auxRatio) / zoom;
            c.left = (c.positions.left * auxRatio) / zoom;
            c.width = (c.positions.width * auxRatio) / zoom;
            c.height = (c.positions.height * auxRatio) / zoom;
            c.originalHeight = c.positions.height;
            c.originalLeft = c.positions.left;
            c.originalWidth = c.positions.width;
            c.originalTop = c.positions.top;
            c.text = c.comment;
            c.id = parseInt(c.id);
            if (c.sticker) {
              c.type = "sticker";
            } else {
              if (!(c.type == "commentArea" || c.type == "sticker")) {
                c.type = "commentArea";
              }
              c.colors = c.style;
            }
            return c;
          });
          setElements(x);
        }
      };
      {
        query.get("year")
          ? xhr3.open(
              "GET",
              baseURL +
                "o/" +
                query.get("oid") +
                "/groups/" +
                query.get("groupId") +
                "/texts/" +
                query.get("textId") +
                "/users/" +
                query.get("correctionId") +
                "/corrections/" +
                query.get("correctorId") +
                "/comments/" +
                query.get("year"),
              true
            )
          : xhr3.open(
              "GET",
              baseURL +
                "o/" +
                query.get("oid") +
                "/groups/" +
                query.get("groupId") +
                "/texts/" +
                query.get("textId") +
                "/users/" +
                query.get("correctionId") +
                "/corrections/" +
                query.get("correctorId") +
                "/comments",
              true
            );
      }
      if (query.get("tka") != null) {
        xhr3.setRequestHeader("authorization", "Bearer " + query.get("tka"));
      }
      xhr3.send();
    } else {
      VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
        var idcorretor;
        if (currentUser.uid == query.get("correctorId")) {
          idcorretor = currentUser.uid;
        } else if (query.get("correctorId") == null) {
          idcorretor = currentUser.uid;
        } else {
          idcorretor = query.get("correctorId");
        }
        var xhr = new XMLHttpRequest();
        xhr.addEventListener("load", () => {
          setTextURL(JSON.parse(xhr.responseText).photoURL);
          setTimeout(function () {
            setImageDimensions({
              width: document.getElementById("essay").naturalWidth,
              height: document.getElementById("essay").naturalHeight,
            });
            setShowingLoad(false);
          }, 2000);
        });
        if (query.get("year") != null) {
          xhr.open(
            "GET",
            baseURL +
              "o/" +
              query.get("oid") +
              "/groups/" +
              query.get("groupId") +
              "/texts/" +
              query.get("textId") +
              "/users/" +
              query.get("correctionId") +
              "/corrections/photourl/" +
              query.get("year"),
            true
          );
        } else {
          xhr.open(
            "GET",
            baseURL +
              "o/" +
              query.get("oid") +
              "/groups/" +
              query.get("groupId") +
              "/texts/" +
              query.get("textId") +
              "/users/" +
              query.get("correctionId") +
              "/corrections/photourl/" +
              new Date().getFullYear(),
            true
          );
        } // xhr.open("GET", baseURL+'o/'+query.get("oid")+'/groups/'+query.get("groupId")+'/texts/'+query.get("textId")+'/users/'+query.get("correctionId")+'/corrections/text', true);
        if (query.get("tka") != null) {
          xhr.setRequestHeader("authorization", "Bearer " + query.get("tka"));
        } else {
          xhr.setRequestHeader("authorization", "Bearer " + tokenA);
        }
        xhr.send();

        //

        var xhr2 = new XMLHttpRequest();
        xhr2.onreadystatechange = function () {
          if (xhr2.readyState === 4) {
            var x = JSON.parse(xhr2.responseText).criteria;
            var final = JSON.parse(xhr2.responseText).final;
            var config = JSON.parse(xhr2.responseText).config;

            if (config) {
              setGradeConfig(config.grade);
            }

            if (final) {
              setFinalComment(final.comment);
              setFinal(true);
            }

            var array = [{}, {}, {}, {}, {}];
            array = array.map(function (a, index) {
              if (x[index]) {
                return x[index];
              } else {
                return null;
              }
            });

            array = array.filter(function (a) {
              return a;
            });

            array.forEach(function (a, index) {
              var i = index + 1;
              switch (i) {
                case 1:
                  setSlider1(a.grade ? a.grade.grade : 0);
                  setComment1(a.comment);
                  break;
                case 2:
                  setSlider2(a.grade ? a.grade.grade : 0);
                  setComment2(a.comment);
                  break;
                case 3:
                  setSlider3(a.grade ? a.grade.grade : 0);
                  setComment3(a.comment);
                  break;
                case 4:
                  setSlider4(a.grade ? a.grade.grade : 0);
                  setComment4(a.comment);
                  break;
                case 5:
                  setSlider5(a.grade ? a.grade.grade : 0);
                  setComment5(a.comment);
                  break;
              }
            });

            setAssignments(array);
          }
        };
        {
          query.get("year")
            ? xhr2.open(
                "GET",
                baseURL +
                  "o/" +
                  query.get("oid") +
                  "/groups/" +
                  query.get("groupId") +
                  "/texts/" +
                  query.get("textId") +
                  "/users/" +
                  query.get("correctionId") +
                  "/corrections/" +
                  query.get("correctorId") +
                  "/assignments/" +
                  query.get("year"),
                true
              )
            : xhr2.open(
                "GET",
                baseURL +
                  "o/" +
                  query.get("oid") +
                  "/groups/" +
                  query.get("groupId") +
                  "/texts/" +
                  query.get("textId") +
                  "/users/" +
                  query.get("correctionId") +
                  "/corrections/" +
                  query.get("correctorId") +
                  "/assignments",
                true
              );
        }
        if (query.get("tka") != null) {
          xhr2.setRequestHeader("authorization", "Bearer " + query.get("tka"));
        } else {
          xhr2.setRequestHeader("authorization", "Bearer " + tokenA);
        }
        xhr2.send();

        //

        var xhr3 = new XMLHttpRequest();
        xhr3.onreadystatechange = function () {
          if (xhr3.readyState === 4) {
            var zoom = essayZoom / 100;
            var auxRatio = imageDimensions.width / dimensions.essayWidth;
            var x = JSON.parse(xhr3.response) || [];
            x = x.map(function (c) {
              c.top = (c.positions.top * auxRatio) / zoom;
              c.left = (c.positions.left * auxRatio) / zoom;
              c.width = (c.positions.width * auxRatio) / zoom;
              c.height = (c.positions.height * auxRatio) / zoom;
              c.originalHeight = c.positions.height;
              c.originalLeft = c.positions.left;
              c.originalWidth = c.positions.width;
              c.originalTop = c.positions.top;
              c.text = c.comment;
              c.id = parseInt(c.id);
              if (c.sticker) {
                c.type = "sticker";
              } else {
                if (!(c.type == "commentArea" || c.type == "sticker")) {
                  c.type = "commentArea";
                }
                c.colors = c.style;
              }
              return c;
            });
            setElements(x);
          }
        };
        {
          query.get("year")
            ? xhr3.open(
                "GET",
                baseURL +
                  "o/" +
                  query.get("oid") +
                  "/groups/" +
                  query.get("groupId") +
                  "/texts/" +
                  query.get("textId") +
                  "/users/" +
                  query.get("correctionId") +
                  "/corrections/" +
                  query.get("correctorId") +
                  "/comments/" +
                  query.get("year"),
                true
              )
            : xhr3.open(
                "GET",
                baseURL +
                  "o/" +
                  query.get("oid") +
                  "/groups/" +
                  query.get("groupId") +
                  "/texts/" +
                  query.get("textId") +
                  "/users/" +
                  query.get("correctionId") +
                  "/corrections/" +
                  query.get("correctorId") +
                  "/comments",
                true
              );
        }
        if (query.get("tka") != null) {
          xhr3.setRequestHeader("authorization", "Bearer " + query.get("tka"));
        } else {
          xhr3.setRequestHeader("authorization", "Bearer " + tokenA);
        }
        xhr3.send();
      });
    }
  }, []);

  useEffect(() => {
    if (selectedElement) {
      var aux = null;
      elements.forEach(function (e) {
        if (e.id == selectedElement) {
          aux = e;
        }
      });
      if (aux && aux.comment) {
        setPopover({ comment: aux.comment, user: aux.teacher });
      }
    }
  }, [selectedElement]);

  function handleClick(event) {}

  function handleMouseDown(event) {
    if (event.target.id === "essay") {
      setMouseDownPoint({
        x: event.nativeEvent.offsetX,
        y: event.nativeEvent.offsetY,
        screenX: event.screenX,
        screenY: event.screenY,
      });
      setSelectedElement(null);
      setMouseDown(true);
    } else if (event.target.id && event.target.id.indexOf("rect") != -1) {
      if (
        event.target.id.match(/\d+/) &&
        event.target.id.match(/\d+/)[0].length
      ) {
        setSelectedElement(event.target.id.match(/\d+/)[0]);
      }
    }
    setMoved(false);
  }

  function handleMouseUp(event) {
    if (hasMoved && selectedTool === "commentArea") {
      setMouseDownPoint({});
      if (selectionArea && selectionArea.width && selectionArea.top) {
        var highestId = 0;
        elements.forEach((el) => {
          if (highestId < el.id) {
            highestId = el.id;
          }
        });
        var aux = selectionArea;
        aux.id = highestId + 1;
        aux.type = "commentArea";
        aux.colors = {
          backgroundColor: defaultBackgroundColor,
          borderColor: defaultBorderColor,
        };
        setElements([...elements, aux]);
        setSelectionArea({});
      }
    }
    setMouseDown(false);
  }

  function handleDragStart() {
    return false;
  }

  function handleMove(event) {
    setMoved(true);
    var top = mouseDownPoint.y;
    var left = mouseDownPoint.x;
    var zoom = essayZoom / 100;
    var originalTop = (top * ratio) / zoom;
    var originalLeft = (left * ratio) / zoom;
    if (
      selectedTool === "commentArea" &&
      mouseDownPoint.x &&
      mouseDownPoint.y &&
      mouseDown
    ) {
      var width = event.screenX - mouseDownPoint.screenX;
      var height = event.screenY - mouseDownPoint.screenY;
      var originalWidth = (width * ratio) / zoom;
      var originalHeight = (height * ratio) / zoom;
      if (height < 20) {
        height = 20;
      }
      if (width < 20) {
        width = 20;
      }
      setSelectionArea({
        top,
        left,
        width,
        height,
        originalLeft,
        originalTop,
        originalHeight,
        originalWidth,
      });
    } else if (selectedTool == "sticker" && selectedSticker) {
      if (event.target.id === "essay") {
        var width = 64;
        var height = 64;
        var originalWidth = width * ratio;
        var originalHeight = height * ratio;
        var left = event.nativeEvent.offsetX;
        var top = event.nativeEvent.offsetY;
        var originalTop = top * ratio;
        var originalLeft = left * ratio;
        setSelectionArea({
          top,
          left,
          width,
          height,
          originalLeft,
          originalTop,
          originalHeight,
          originalWidth,
        });
      }
    }
  }

  function handleSelectionAreaUpdate(selectionArea) {
    setSelectionArea(selectionArea);
  }

  function handleResize(style, isShiftKey, type, elementType) {
    let { top, left, width, height } = style;
    top = Math.round(top);
    left = Math.round(left);
    width = Math.round(width);
    height = Math.round(height);
    var zoom = essayZoom / 100;
    var originalHeight = (height * ratio) / zoom;
    var originalWidth = (width * ratio) / zoom;

    if (elementType === "sticker") {
      var biggestSize = height > width ? height : width;
      height = biggestSize;
      width = biggestSize;
    }

    var newElements = elements.map((el) => {
      if (el.id == selectedElement) {
        return {
          ...el,
          top,
          left,
          width,
          height,
          originalWidth,
          originalHeight,
        };
      } else {
        return el;
      }
    });
    setElements(newElements);
  }

  function handleRotate(rotateAngle) {
    setSelectionArea({
      rotateAngle,
    });
  }

  function handleDrag(deltaX, deltaY) {
    var newElements = elements.map((el) => {
      var top = el.top + deltaY;
      var left = el.left + deltaX;
      var zoom = essayZoom / 100;
      var originalTop = (top * ratio) / zoom;
      var originalLeft = (left * ratio) / zoom;
      if (el.id == selectedElement) {
        return {
          ...el,
          top,
          left,
          originalTop,
          originalLeft,
        };
      } else {
        return el;
      }
    });
    setElements(newElements);
  }

  function setComment(e, id) {
    setElements(
      elements.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            text: e.target.value,
          };
        } else {
          return el;
        }
      })
    );
  }

  function setColor(colors, id) {
    setElements(
      elements.map((el) => {
        if (el.id === id) {
          return {
            ...el,
            colors: colors,
          };
        } else {
          return el;
        }
      })
    );
  }

  function setDeleted(id) {
    setElements(
      elements.filter((el) => {
        if (el.id !== id) {
          return el;
        }
      })
    );
  }

  function getRectColor(el) {
    if (el.type === "commentArea") {
      if (el.id == selectedElement) {
        return el.colors.selectedColor;
      } else if (el.id == hoveredElement) {
        return el.colors.dotColor;
      } else {
        return el.colors.halfColor;
      }
    } else if (el.type === "sticker") {
      return null;
    }
  }

  function setVisibility() {
    setTimeout(() => {
      setShowingCards(!showingCards);
    }, 300);
    setTimeout(
      () => {
        setCardsVisible(!cardsVisible);
      },
      rightBarExpanded ? 1000 : 0
    );
    setRightBarExpanded(!rightBarExpanded);
  }

  const steps = isFinal
    ? ["Competências", "Nota", "Considerações"]
    : ["Competências", "Nota"];

  const handleNext = (isFinish) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    if (isFinish) {
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  var dialog = (
    <Dialog
      aria-labelledby="simple-dialog-title"
      open={popover}
      onClose={() => setPopover(false)}
    >
      <div
        style={{
          width: "300px",
          padding: "10px",
          height: "fit-content",
          fontSize: "18px",
        }}
      >
        <span>Comentado por: {popover.user}</span>
        <p>{popover.comment}</p>
      </div>
    </Dialog>
  );

  var commentTags = [];
  var commentArea = [];

  if (showElements) {
    elements.forEach((ca, index) => {
      if (ca.type === "commentArea") {
        commentArea.push(
          <ResizableRect
            left={ca.left}
            top={ca.top}
            width={ca.width}
            height={ca.height}
            auxStyle={{
              ...styles.commentArea,
              backgroundColor: ca.colors.backgroundColor,
              borderColor: ca.colors.borderColor,
            }}
            onClick={() => setSelectedElement(ca.id)}
            onHoverStart={() => setHoveredElement(ca.id)}
            onHoverEnd={() => setHoveredElement(null)}
            //text={ca.text}
            minWidth={20}
            minHeight={20}
            id={ca.id}
            sticker={ca.sticker}
          />
        );
      } else if (ca.type === "sticker") {
        commentArea.push(
          <ResizableRect
            left={ca.left}
            top={ca.top}
            width={ca.width}
            height={ca.height}
            auxStyle={{
              ...styles.commentArea,
              backgroundColor: "transparent",
              borderColor: ca.id == selectedElement ? null : "transparent",
            }}
            onClick={() => setSelectedElement(ca.id)}
            id={ca.id}
            sticker={ca.sticker}
          />
        );
      }
    });
  }

  if (showElements) {
    var virtualCommentArea =
      selectionArea.height && selectionArea.top ? (
        <ResizableRect
          left={selectionArea.left}
          top={selectionArea.top}
          width={selectionArea.width}
          height={selectionArea.height}
          rotateAngle={selectionArea.rotateAngle}
          onResize={handleResize}
          onDrag={handleDrag}
          auxStyle={{
            backgroundColor: defaultBackgroundColor,
            borderColor: defaultBorderColor,
            zIndex: 999,
          }}
          sticker={selectedSticker}
        />
      ) : (
        <div />
      );
  }

  var cards = [];
  elements.forEach((el, index) => {
    cards.push(
      <Card
        data={el}
        isSelected={selectedElement == el.id}
        onClick={() => setSelectedElement(el.id)}
        text={el.text}
        onChangeText={(e) => setComment(e, el.id)}
        onChangeColor={setColor}
        id={el.id}
        onDelete={() => setDeleted(el.id)}
        type={el.type}
        backgroundColor={
          el.colors && el.colors.backgroundColor
            ? el.colors.backgroundColor
            : defaultBackgroundColor
        }
        borderColor={
          el.colors && el.colors.borderColor
            ? el.colors.borderColor
            : defaultBorderColor
        }
        showingCards={showingCards}
        readOnly={true}
      />
    );
  });

  function getFinalGrade() {
    if (gradeConfig) {
      var max = gradeConfig.max;
      var grade = slider1 + slider2 + slider3 + slider4 + slider5;

      return "Nota final: " + grade + "/" + max;
    }
  }

  function getStepContent(index) {
    if (assignments) {
      if (index == 0) {
        return (
          <div>
            <div style={{ flexGrow: 1 }}>
              <AppBar position="static">
                <Tabs
                  value={assignmentIndex}
                  onChange={(ev, newIndex) => setAssignmentIndex(newIndex)}
                  aria-label="simple tabs example"
                >
                  {getTabs()}
                </Tabs>
              </AppBar>
              {getTabContent()}
            </div>
          </div>
        );
      }
      if (index == 1) {
        return (
          <div>
            {getSliders()}
            <div>
              <Typography>{getFinalGrade()}</Typography>
            </div>
          </div>
        );
      }
      if (index == 2 && isFinal) {
        return (
          <TextField
            id="standard-multiline-flexible"
            label={steps[index]}
            multiline
            rows={4}
            variant="outlined"
            value={finalComment}
            fullWidth={true}
          />
        );
      }
      if (index == 3 || (index == 2 && !isFinal)) {
        return (
          <div>
            <Typography>LOL NOTAS</Typography>
          </div>
        );
      }
    }
  }

  function getSliderSet(index, value) {
    index = index + 1;
    switch (index) {
      case 1:
        setSlider1(value);
        break;
      case 2:
        setSlider2(value);
        break;
      case 3:
        setSlider3(value);
        break;
      case 4:
        setSlider4(value);
        break;
      case 5:
        setSlider5(value);
        break;
    }
  }

  function getSliderValue(index, value) {
    index = index + 1;
    switch (index) {
      case 1:
        return slider1;
      case 2:
        return slider2;
      case 3:
        return slider3;
      case 4:
        return slider4;
      case 5:
        return slider5;
    }
  }

  function getSliders() {
    return assignments.map(function (a, index) {
      return (
        <div>
          <Typography id="discrete-slider" gutterBottom>
            {a.name}
          </Typography>
          <Slider
            defaultValue={getSliderValue(index)}
            getAriaValueText={(value) => {
              return value ? value : "0";
            }}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={a.grade.breakpoint}
            marks
            disabled
            min={a.grade.min}
            max={a.grade.max}
          />
        </div>
      );
    });
  }

  function getTabs() {
    var array = [];
    assignments.forEach(function (a, index) {
      array.push(<Tab label={a.name} {...a11yProps(index)} />);
    });
    return array;
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function getTabContent() {
    var array = [];

    var getOnChange = function (value, index) {
      index = index + 1;
      switch (index) {
        case 1:
          setComment1(value);
          break;
        case 2:
          setComment2(value);
          break;
        case 3:
          setComment3(value);
          break;
        case 4:
          setComment4(value);
          break;
        case 5:
          setComment5(value);
          break;
      }
    };

    assignments.forEach(function (a, index) {
      array.push(
        <div
          role="tabpanel"
          hidden={assignmentIndex !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          style={{ marginTop: "15px" }}
        >
          {assignmentIndex === index && (
            <div>
              <p>{a.content}</p>
              <TextField
                id="standard-multiline-flexible"
                label={a.name}
                multiline
                rows={4}
                variant="outlined"
                value={getCommentValue(index)}
                fullWidth={true}
              />
              {/* <IconButton color="primary" aria-label="upload picture" component="span" onClick={() => setPopover(a.content)}>
                                <HelpIcon style={{fontSize: 30, color: 'grey'}} />  
                            </IconButton> */}
            </div>
          )}
        </div>
      );
    });
    return array;
  }

  function getCommentValue(index) {
    index = index + 1;
    switch (index) {
      case 1:
        return comment1;
      case 2:
        return comment2;
      case 3:
        return comment3;
      case 4:
        return comment4;
      case 5:
        return comment5;
    }
  }

  return (
    <div>
      {dialog}
      <Backdrop open={showingLoad} style={{ color: "#fff", zIndex: "999" }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ToolBar
        selectedTool={selectedTool}
        setSelectedTool={setSelectedTool}
        setSelectedSticker={setSelectedSticker}
        showElements={showElements}
        setShowElements={setShowElements}
        setZoom={setEssayZoom}
        zoom={essayZoom}
        setDefaultBackgroundColor={setDefaultBackgroundColor}
        setDefaultBorderColor={setDefaultBorderColor}
        hideSecondBar={true}
      ></ToolBar>
      <div className="EssayCorrector" style={{ display: "flex" }}>
        <div style={styles.imageBase}>
          <div
            style={{
              ...styles.imageContainer,
              width: isMobile ? "100vw" : "70vw",
            }}
            id="essayContainer"
            onClick={handleClick}
          >
            <img
              id="essay"
              src={textURL}
              draggable={false}
              alt="essay"
              style={{ width: essayZoom + "%", userSelect: "none" }}
            />
            {virtualCommentArea}

            {commentArea}

            {commentTags}
          </div>
        </div>

        {isMobile ? (
          <div />
        ) : (
          <div
            style={{
              ...styles.rightBar,
              width: rightBarExpanded ? "500px" : "55px",
            }}
          >
            <div
              style={{
                height: "55px",
                width: "100%",
                backgroundColor: "#5f5d5d",
              }}
            >
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                onClick={setVisibility}
              >
                {rightBarExpanded ? (
                  <KeyboardArrowRightIcon
                    style={{ fontSize: 30, color: "rgb(224,224,224)" }}
                  />
                ) : (
                  <KeyboardArrowLeftIcon
                    style={{ fontSize: 30, color: "rgb(224,224,224)" }}
                  />
                )}
              </IconButton>
            </div>
            <div style={{ marginBottom: "20px" }}>
              {cardsVisible ? cards : <div />}
            </div>
          </div>
        )}
      </div>
      <div
        style={{
          maxWidth: "75vw",
          marginLeft: "auto",
          minWidth: "350px",
          marginRight: "auto",
        }}
      >
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                <Typography>{getStepContent(index)}</Typography>
                <div className={classes.actionsContainer}>
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Voltar
                    </Button>
                    {activeStep !== steps.length - 1 ? (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          handleNext(activeStep === steps.length - 1)
                        }
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1
                          ? "Finalizar"
                          : "Próximo"}
                      </Button>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
}

const styles = {
  imageBase: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "rgb(153,51,204)",
  },

  rightBar: {
    height: "820px",
    justifyContent: "center",
    backgroundColor: "rgb(36,35,37)",
    overflow: "auto",
    transition: "width 1s",
  },
  imageContainer: {
    width: "70vw",
    height: "83vh",
    overflow: "scroll",
    position: "relative",
    marginTop: "20px",
  },
  overlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
  },
  commentArea: {
    cursor: "pointer",
    zIndex: 555,
    borderWidth: "2px",
  },
};

export default EssayViewer;
