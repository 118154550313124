import React, { useState, useEffect } from "react";
import "fontsource-roboto";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import firebase from "firebase/app";
import { VerifyAuth, goTo, baseURL } from "./webHandler";
import { useSessionStorage } from "react-use-storage";
import { BrowserRouter as Router, useLocation } from "react-router-dom";

function Groups() {
  const [organizations, setOrganizations] = useState([]);
  const [requestDone, setRequestDone] = useState(false);
  const [year, setYear] = useState(2024);
  const [credentials, seCredentials, removeCredentials] =
    useSessionStorage("credentials");
  const [bearer, setBearer] = useSessionStorage("bearer");

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  useEffect(() => {
    setRequestDone(false);
    VerifyAuth(bearer, setBearer, function (tokenA, currentUser) {
      //console.log(tokenA)
      var xhr = new XMLHttpRequest();
      xhr.addEventListener("load", () => {
        setRequestDone(true);
        setOrganizations(JSON.parse(xhr.responseText));
      });
      xhr.open(
        "GET",
        baseURL +
          "o/" +
          query.get("oid") +
          "/users/" +
          currentUser.uid +
          "/groups/" +
          year +
          "/list",
        true
      );
      xhr.setRequestHeader("authorization", "Bearer " + tokenA);
      xhr.send();
    });
  }, [year]);

  const getCardColor = function (o) {
    // console.log(o.groupID)
    if (
      o.groupID == "1AxDf7R0sP" ||
      o.groupID == "n5AeHDG8hX" ||
      o.groupID == "Q7VzSfVDQ5" ||
      o.groupID == "e03Q9Utgg5" ||
      o.groupID == "HsKXbsqYP6" ||
      o.groupID == "jw1Mtnpf4V" ||
      o.groupID == "FfwUAMAF6T" ||
      o.groupID == "jRub8YU8yE" ||
      o.groupID == "nmgdhzmq4z"
    ) {
      return "#06D6A0";
    } else if (
      o.groupID == "FVZKzNw3Vu" ||
      o.groupID == "qVXjJ3hNxb" ||
      o.groupID == "24UX8t5Qax" ||
      o.groupID == "ARvDhS0ntM" ||
      o.groupID == "ZEEN2FZzGF" ||
      o.groupID == "jv2RdF206a" ||
      o.groupID == "uEMS63kDh8" ||
      o.groupID == "3DPn169Ekj" ||
      o.groupID == "q9559M3z7a" ||
      o.groupID == "VVnJzkhb26" ||
      o.groupID == "ry0qpBn2Ft" ||
      o.groupID == "uXP7EAav75" ||
      o.groupID == "XnJUjhSTYZ"
    ) {
      return "#FF6600";
    } else {
      return "#6F2495";
    }
  };

  const groupClick = function (o) {
    goTo(
      "essays?oid=" +
        query.get("oid") +
        "&groupId=" +
        o.groupID +
        "&groupName=" +
        o.name +
        "&year=" +
        year
    );
  };

  return (
    <div style={styles.outterDiv}>
      <div style={styles.topBar}>
        <Typography
          variant="h3"
          style={{ paddingTop: "8px", paddingLeft: "20px" }}
        >
          Grupos
        </Typography>
        {year == 2024 ? (
          <button onClick={() => setYear(2023)} style={styles.btnGroup}>
            2023
          </button>
        ) : (
          <button onClick={() => setYear(2024)} style={styles.btnGroup}>
            2024
          </button>
        )}
      </div>
      <h1 style={{ marginLeft: 20 }}>Turmas - {year}</h1>
      <div style={styles.innerContent}>
        {organizations.map(function (o) {
          var chips =
            o.info && o.info.tags ? (
              o.info.tags.map(function (t) {
                return (
                  <Chip size="small" label={t} style={{ marginRight: "5px" }} />
                );
              })
            ) : (
              <div />
            );
          return (
            <Paper
              elevation={3}
              style={styles.card}
              onClick={() => groupClick(o)}
            >
              <div
                style={{
                  ...styles.cardUpperBar,
                  backgroundColor: getCardColor(o),
                }}
              >
                <div style={{ height: "100%", width: "100px" }}>
                  <Avatar
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "20px",
                      marginBottom: "20px",
                    }}
                    src={o.style.icon}
                  />
                </div>
                <div>
                  <div style={{ marginTop: "12px" }}>
                    <Typography style={{ color: "white" }} variant="h5">
                      {o.name}
                    </Typography>
                  </div>

                  <div>
                    <Typography variant="subtitle1">
                      {o.info ? o.info.subdomain : ""}
                    </Typography>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  height: "40px",
                  paddingTop: "5px",
                  paddingLeft: "10px",
                }}
              >
                {chips}
              </div>
              <div style={styles.textBox}>
                <Typography variant="subtitle1">
                  {o.info ? o.info.description : ""}
                </Typography>
              </div>
            </Paper>
          );
        })}

        {!requestDone ? (
          <div style={styles.card}>
            <Skeleton variant="rect" width={380} height={220} />
          </div>
        ) : (
          <div />
        )}
        {!requestDone ? (
          <div style={styles.card}>
            <Skeleton variant="rect" width={380} height={220} />
          </div>
        ) : (
          <div />
        )}
        {!requestDone ? (
          <div style={styles.card}>
            <Skeleton variant="rect" width={380} height={220} />
          </div>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}

const styles = {
  outterDiv: {
    width: "100%",
    height: "100%",
  },
  topBar: {
    height: "80px",
    backgroundColor: "rgb(109,109,109)",
    display: "flex",
    justifyContent: "space-between",
    padding: "0px 20px",
  },
  btnGroup: {
    color: "white",
    backgroundColor: "transparent",
    borderColor: "transparent",
    cursor: "pointer",
    fontsize: "25px",
  },
  innerContent: {
    height: "100%",
    marginLeft: "70px",
    marginTop: "50px",
    marginRight: "70px",
    marginBottom: "50px",
    flexFlow: "wrap",
    display: "flex",
  },
  card: {
    margin: "30px",
    width: "380px",
    height: "220px",
    cursor: "pointer",
  },
  cardUpperBar: {
    width: "100%",
    height: "80px",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    backgroundColor: "orange",
    display: "flex",
  },
  textBox: {
    maxWidth: "330px",
    maxHeight: "80px",
    marginLeft: "20px",
    overflow: "hidden",
    display: "Webkit-box",
    WebkitLineClamp: 3,
    marginLeft: "20px",
  },
};

export default Groups;
